.indv-payment-success-sec p {
  margin-bottom: 0;
  font-weight: 500;
}
.indv-payment-success-done-sec-up {
  padding-top: 50px;
}
.indv-payment-success-done-sec-up-img img {
  width: 25%;
}
.payment-success-card-select {
  border: 3px solid #88ae47;
  border-radius: 24px;
  height: 390px;
}

.indv-payment-success-sec-head {
  font-weight: 600;
  font-size: 15px;
}

.indv-payment-success-sec-text1,
.indv-payment-success-sec-text2 {
  font-size: 13px;
}

.indv-payment-success-done-sec-down-head p {
  margin-bottom: 0;
  font-weight: 600;
  font-size: 15px;
}

.indv-payment-foot {
  box-shadow: 0px -2px 10px 0px rgba(0, 0, 0, 0.26);
  background-color: #fff;
}

.carbon-offset-payment-success-foot-btn {
  background-color: #88ae47 !important;
  padding: 0px 56px !important;
  border-radius: 6px !important;
  color: #fff !important;
  height: 45px !important;
}
.indv-payment-success-alert {
  background-color: #fff;
  padding: 32px;
  border-radius: 12px;
}

.indv-payment-success-alert p {
  margin-bottom: 0;
}

.indv-payment-success-alert-head {
  font-size: 14px;
  font-weight: 600;
}

.indv-payment-success-alert-text {
  font-size: 13px;
}

.indv-payment-success-alert-img {
  display: flex;
  justify-content: center;
}

.indv-payment-success-alert-img img {
  width: 205px;
}

.payment-success-foot .ant-affix {
  z-index: 9999 !important;
}
