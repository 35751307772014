.offset_details h1 {
  text-align: center;
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
}
.offset_details_head p {
  margin: 0;
  font-size: 23px;
}
.offset_down_heade_back {
  padding: 22px 12px;
  background-image: linear-gradient(
      rgb(201 201 201 / 50%),
      rgba(201 201 201 / 50%)
    ),
    url("https://minio.b120578.dev.eastus.az.svc.builder.cafe/sbucket/variants/fk4eqwc85uqy5e13gfna6q7tu9tx/6f7daa6c86a9bc2c53f5d0684447f253adfa8f13401d95f9d66464245ed9ff2e?response-content-disposition=inline%3B%20filename%3D%22greenfield.jpg%22%3B%20filename%2A%3DUTF-8%27%27greenfield.jpg&response-content-type=image%2Fjpeg");
  background-size: cover !important;
}
.offset_down_heade_back_padding_top{
  padding-top: 70px;
}
.offset_down_heade {
  display: flex;
  justify-content: center;
}
.offset_down_heade h1 {
  color: #000000;
  text-align: initial;
  font-size: 27px;
  line-height: 35px;
  font-weight: 600;
}
.offset_down_heade p {
  color: #000000;
  text-align: initial;
}
.Tool_user {
  background-color: #fff;
}
.Tool_user_section {
  display: flex;
}
.Tool_user_number {
  background: -webkit-linear-gradient(#88ae47, #eee);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 75px !important;
  font-weight: bolder !important;
  padding: 0;
}
.down_Li {
  margin: 0px 0px 18px 36px;
  list-style-image: url("../../assets/offsetImg/emission_ul.svg") !important;
}

.down_Li::marker {
  margin-top: 8px;
}

.offset-indv-car-img-sec {
  max-height: 455px;
  min-height: 455px;
}

.offset-indv-flight-img-sec {
  max-height: 385px;
  min-height: 385px;
}

.emissionsonflight-text2 {
  padding: 12px 36px;
}

.emissionsonflight-text2 p {
  font-weight: 410;
  font-size: 15px;
  text-align: justify;
}

.emissionsonflight-text2 p a {
  cursor: pointer;
  text-decoration: underline !important;
  font-weight: 800;
}
.a_tag {
  text-decoration: inline !important;
  margin: 0 6px 0 0;
  font-weight: 400;
}
.flight_upper_headding {
  position: absolute;
  left: -10px;
  top: 28%;
}
.flight_down_headding {
  position: absolute;
  height: 103px;
  right: 0px;
  bottom: 0%;
}
.seprate_text {
  text-align: justify;
  font-weight: 400;
  font-size: 15px;
}

.seprate_text span {
  position: relative;
  top: -5px;
  left: 8px;
}

.offset-emmision-new-p p {
  font-size: 23px !important;
  font-weight: 400;
}

.offset-emmision-new-h1 h1 {
  font-size: 26px !important;
  margin-top: 12px;
}

.offset-emmision-new-h1 h1 span {
  font-size: 26px !important;
  font-weight: 600 !important;
}
.subscription_btn {
  display: flex;
  justify-content: right;
}
@media screen and (min-width: 370px) and (max-width: 767px) {
  .offset_down_heade h1{
    font-size: 22px;
  }
}