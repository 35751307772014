@import url('../src/fonts/stylesheet.css');
@import url('../../blocks/email-account-login/assets/Login.css');
@import url('../../blocks/forgot-password/assets/ForgotPassword.css');
@import url('../../blocks/email-account-registration/assets/IndvSignup.css');
@import url('../../components/src/Footer/Footer.css');
@import url('../../components/src/Header/Header.css');
@import url('../../blocks/dashboard/src/IndividualNav.web.css');
@import url('../../blocks/dashboard/src/IndvOffset/IndividualOffset.css');
@import url('../../blocks/dashboard/src/Dashboard.css');
@import url('../../blocks/dashboard/src/IndividaualScreen.web.css');
@import url('../../blocks/dashboard/src/IndvLayout/indvlayout.css');
@import url('../../blocks/dashboard/src/IndvFaq/indvfaq.css');
@import url('../../blocks/dashboard/src/IndvAddToCart/indvAddtoCart.css');
@import url('../../blocks/dashboard/src/IndvPrivacy/indvPrivacy.css');
@import url('../../blocks/CustomisableUserProfiles/src/reset-password/IndvidualProfileResetPassword.css');
@import url('../../blocks/dashboard/src/IndvClimateCertificate/indvClimate.css');
@import url('../../blocks/dashboard/src/IndvPaymentSuccess/IndvPaymentSuccess.css');
@import url('../../blocks/dashboard/src/LandingPages/IndiviLandingCommon.css');
@import url('../../blocks/dashboard/src/IndvTour/IndividualTour.web.css');
@import url('../../blocks/dashboard/src/Business/BusinessTakeTour/BusinessTour.web.css');
@import url('../../blocks/dashboard/src/Business/BusinessOffset/BusinessOffset.css');
@import url('../../blocks/dashboard/src/IndvOffset/IndividualOffsetDetails.web.css');
@import url('../../blocks/dashboard/src/IndvProject/IndividualProject.web.css');
@import url('../../blocks/dashboard/src/Business/BusinessScreen.web.css');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
  --color-primary: #3f7a4f;
  --color-black: #1c213a;
  --color-dark-grey: #7c8188;
  --color-white: #ffffff;
  --color-blue: #527bbe;
  --color-orange: #f67054;
  --color-sky-blue: #007bff;
  --color-green: #038b57;
  --color-red: #ff471a;
  --color-gray: #7c8188;
  --color-gold-yellow: #ffa500;
  --color-light-gray: #f2f6f8;
  --color-light-gray-hover: #7c81884f;
  --color-individual: #88ae47;
  --color-individual-firstsquareT: #7f9065;
  --color-individual-secondsquareT: #96aeb4;
  --color-individual-firstsquare: #e8f0db;
  --color-individual-secondsquare: #e1f4fa;
  --color-individual-white: #f4f8ea;
  --color-individual-gray: #cbcbcb;
  --color-individual-gray1: #c3c3c3;
  --color-green-individual-main: #88ae47;
  --color-green-individual-backgroud: #f8f6f7;
  --color-tabgreen-individual-backgroud: #bae481;
  --color-individual-Inner-box: #e6f1cf;
  --color-individual-i-b-amount: #a4b0b2;
  --color-individual-i-b-amount1: #94bcbf;
}

* {
  font-family: 'Poppins', sans-serif;
}

body {
  padding-right: 0 !important;
  /* background-color: var(--color-individual-gray) !important; */
  user-select: none;
}
iframe {
  pointer-events: none !important;
}

li {
  list-style: none;
}

.modal-content {
  border-radius: 10px;
}

@media (min-width: 768px) {
  .cm-small-modal-6 {
    max-width: 600px;
  }
}

@media (max-width: 767.92px) {
  .cm-small-modal-6 {
    max-width: 335px;
    margin-left: auto;
    margin-right: auto;
  }
}

.ant-input {
  border-radius: 10px !important;
}
/* .ant-select-selector {
  border-color: rgba(204, 204, 204, 0.5) !important;
  box-shadow: none !important;
} */
