.auth-bg {
  background: url("https://minio.b120578.dev.eastus.az.svc.builder.cafe/sbucket/variants/k64fzey62bj1y9cz7qgckdnqhr86/6f7daa6c86a9bc2c53f5d0684447f253adfa8f13401d95f9d66464245ed9ff2e?response-content-disposition=inline%3B%20filename%3D%221eeaff1adb2ac227e5c20751c15edde4f86081b8.jpg%22%3B%20filename%2A%3DUTF-8%27%271eeaff1adb2ac227e5c20751c15edde4f86081b8.jpg&response-content-type=image%2Fjpeg");
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
}
.term-cond-btn-foot-signup {
  background: #88ae47 !important;
  color: #fff !important;
  width: 125px;
  border-radius: 6px !important;
}
.idv-signup-modal-term-condition{
  top: 10px !important;
}
.idv-signup-modal-term-condition .ant-modal-content {
  border-radius: 10px;
}
.privacy_height{
  max-height: 185px;
  overflow-y: auto;
}
.privacy_height_adjust::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.privacy_height_adjust::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5;
}

.privacy_height_adjust::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #e0e0e0;
}
.privacy_height_adjust{
  height: 500px;
  overflow: scroll;
  overflow-x: hidden;
}
.inlinePera p:last-child{
  display: inline;
 }
.f-w6 {
  font-weight: 600;
  padding: 0 5px;
}
.term-cond-signup-head p {
  font-size: 18px;
  font-weight: 800;
  padding-bottom: 10px;
}
.padding-data-ui{
  padding: 108px 0;
}