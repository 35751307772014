.reset-pass-succ-modal{
    min-height: 350px !important;
}

.indv-profile-bg{
    background: url("https://minio.b120578.dev.eastus.az.svc.builder.cafe/sbucket/variants/k64fzey62bj1y9cz7qgckdnqhr86/6f7daa6c86a9bc2c53f5d0684447f253adfa8f13401d95f9d66464245ed9ff2e?response-content-disposition=inline%3B%20filename%3D%221eeaff1adb2ac227e5c20751c15edde4f86081b8.jpg%22%3B%20filename%2A%3DUTF-8%27%271eeaff1adb2ac227e5c20751c15edde4f86081b8.jpg&response-content-type=image%2Fjpeg");
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
}
.div_class{
    background-color: transparent;
}
.ant-btn-default.csncel_subcription{
    background-color: #fff !important;
    color: var(--bs-dark) !important;
}