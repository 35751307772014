#components-layout-demo-responsive .logo {
  height: 32px !important;
  margin: 16px !important;
  background: rgba(255, 255, 255, 0.2) !important;
}

.indv-layout .ant-layout-header {
  padding: 0px 24px !important;
  box-shadow: 0px 12px 10px -10px rgb(0 0 0 / 7%);
  z-index: 1;
}

.indv-logo-head {
  height: 64px !important;
  background: #fff !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.site-layout-sub-header-background {
  background: #fff !important;
  line-height: unset !important;
  display: flex !important;
  justify-content: flex-end !important;
}

.site-layout-sub-header-background2 {
  background: #fff !important;
  line-height: unset !important;
  display: flex !important;
  justify-content: space-between !important;
}

.site-layout-background {
  background: #fff !important;
}

.indv-dash-sidebar {
  background: #f8f6f7 !important;
}

.indv-dash-sidebar .ant-menu.ant-menu-dark {
  background: #f8f6f7 !important;
}

.indv-dash-sidebar .ant-menu-title-content {
  color: #89898b !important;
}

.indv-dash-sidebar .anticon {
  color: #89898b !important;
}

.indv-dash-sidebar
  .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal)
  .ant-menu-item-selected {
  background-color: #eeeced !important;
}

.indv-dash-sidebar .ant-menu-dark .ant-menu-item > span > a {
  color: #89898b !important;
}

.indv-dash-side-img img {
  width: 24px;
  margin-right: 10px;
}
.image_side_nav{
  position: absolute;
  left: 0;
  width: 7px;
  top: 13px;
  display: none;
}
.indv-dash-sidebar .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  /* width: 85%;
  margin-left: 25px; */
  /* background-color: #eeeced !important; */
  /* border-radius: 6px;
  padding-left: 12px !important; */
}
.ant-menu-dark.ant-menu-inline .ant-menu-item, .ant-menu-dark.ant-menu-inline .ant-menu-submenu-title {
  /* width: 100%;
  padding-left: 35px !important; */
}