.indv-faq-head {
  display: flex;
  align-items: center;
}

.indv-faq-head p {
  margin-bottom: 0px;
  font-weight: 600;
  font-size: 18px;
}

.indv-faq-head button {
  border: none;
  border-radius: 36px;
  height: 34px;
  width: 34px;
  margin-right: 25px;
}

.indv-faq-sec .ant-collapse > .ant-collapse-item {
  background: #ffffff !important;
  border: none;
  margin-bottom: 10px;
  border-radius: 10px;
  box-shadow: 0px 0px 13px 5px rgba(0, 0, 0, 0.12);
  padding: 8px 0 !important;
}

.indv-faq-sec .ant-collapse {
  background-color: none !important;
  border: none !important;
}

.indv-faq-sec .ant-collapse > .ant-collapse-item:last-child,
.ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
  border-radius: 10px !important;
}

.indv-faq-sec .ant-collapse-content {
  border-top: unset !important;
}
.indv-faq-sec .collapse_arrow{
  width: 22px;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header{
  font-weight: bolder;
}
.ul_li_list li{
  list-style: unset ;
}