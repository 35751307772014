.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector {
  padding: 6px 11px !important;
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector {
  height: 50px !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 10px !important;
}

.offset-onetime-order-summery .ant-input {
  height: 50px !important;
  border-radius: 10px !important;
}
.not_edit{
  cursor: not-allowed;
}
.gift-code-input .ant-input {
  height: 40px !important;
}
.gift-code-input .ant-input-affix-wrapper {
  border-radius: 10px !important;
}

.ant-input:focus,
.ant-input-focused {
  border-color: #c6c6c6 !important;
  box-shadow: none !important;
}

.ant-input:hover {
  border-color: #c6c6c6 !important;
}

.admin_carbon_offset
  .ant-tabs
  .ant-tabs-nav
  .ant-tabs-nav-wrap
  .ant-tabs-nav-list
  .ant-tabs-tab.ant-tabs-tab-active
  .ant-tabs-tab-btn {
  color: #121212;
  font-size: 15px;
}

.admin_carbon_offset
  .ant-tabs
  .ant-tabs-nav
  .ant-tabs-nav-wrap
  .ant-tabs-nav-list
  .ant-tabs-tab {
  color: #b0b0b0;
  font-size: 15px;
}

.admin_carbon_offset
  .ant-tabs
  .ant-tabs-nav
  .ant-tabs-nav-wrap
  .ant-tabs-nav-list
  .ant-tabs-tab
  + .ant-tabs-tab {
  margin: 0 0 0 80px;
}

.admin_carbon_offset {
  width: 100%;
  z-index: 9;
}

.admin_carbon_offset .ant-tabs-nav-wrap {
  justify-content: center !important;
}
.subscription-disable-slider{
  position: relative;
}
.subscription-disable-slider .rec-arrow-right:disabled, .subscription-disable-slider .rec-arrow-left:disabled{
  cursor: not-allowed;
  display: none;
}

.subscription-disable-slider .rec-arrow-right {
  position: absolute;
  top: 0px;
  right: 20px;
  border: 0 !important;
  box-shadow: none;
  font-size: 14px;
  width: 25px;
  height: 25px;
  min-width: unset;
  border-radius: 5px;
  z-index: 999;
  cursor: pointer;
  background: #ececec;
  color: #706e6e;
  line-height: 25px;
}
.subscription-disable-slider .rec-arrow-left {
  position: absolute;
  top: 0px;
  right: 52px;
  border: 0 !important;
  box-shadow: none;
  font-size: 14px;
  width: 25px;
  height: 25px;
  min-width: unset;
  border-radius: 5px;
  z-index: 999;
  cursor: pointer;
  background: #ececec;
  color: #706e6e;
  line-height: 25px;
}

.subscription-disable-slider .rec-arrow-right:hover, .subscription-disable-slider .rec-arrow-right:hover:enabled, .subscription-disable-slider .rec-arrow-right:focus:enabled{
  border: 0 !important;
  box-shadow: none;
  background: #ececec !important;
  background-color: #ececec !important;
  color: #706e6e !important;
}

.carbon-offset-onetime-img-card {
  background: #fff;
  padding: 12px 8px 28px 15px;
  box-shadow: 0px 0px 16px -3px rgb(0 0 0 / 51%) !important;
  min-width: 110px !important;
  max-width: 110px !important;
  border-radius: 8px;
  height: 100%;
  cursor: pointer;
}
.carbon-business-offset-onetime-img-card {
  background: #fff;
  padding: 12px 8px 0px 15px;
  box-shadow: 0px 0px 16px -3px rgb(0 0 0 / 51%) !important;
  min-width: 110px !important;
  max-width: 110px !important;
  border-radius: 8px;
  height: 100%;
  cursor: pointer;
}

.active-bg-grey-onetime-card {
  background: #f6ffea !important;
  border: 0.5px solid #88ae47 !important;
}

.custom-pad-offset-onetime-card {
  padding: 12px 8px 5px 15px !important;
}

.custom-pad-offset-onetime-card img {
  height: 24px !important;
  width: 20px !important;
}

.carbon-offset-onetime-img-card p {
  color: #30302e;
  font-weight: 500;
  margin: 8px 0;
  font-size: 15px;
}
.carbon-business-offset-onetime-img-card p {
  color: #30302e;
  font-weight: 500;
  margin: 8px 0;
  font-size: 15px;
}

.carbon-offset-onetime-img-card img {
  width: 24px;
}
.carbon-business-offset-onetime-img-card img {
  width: 24px;
}

.carbon-offset-onetime-life-card-text-head p {
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 0;
}

.carbon-offset-onetime-life-card-text-head img {
  width: 22px;
  height: 22px;
}

.carbon-offset-onetime-life-card-img img {
  width: 100%;
  border-radius: 22px;
  cursor: pointer;
  height: 270px;
}

.life-card-img-select {
  border: 3px solid #88ae47;
  border-radius: 24px;
  height: 270px;
}

.carbon-offset-onetime-life-sec {
  padding: 15px 10px 10px 10px;
}

.carbon-offset-bg-img {
  margin-top: -40px;
}
.carbon-offset-onetime-life-sec-card {
  margin-top: 15px;
  position: relative;
}

.carbon-offset-flight-sec
  .ant-card
  .ant-card-head
  .ant-card-head-wrapper
  .ant-card-head-title {
  font-weight: 900;
}

.carbon-offset-onetime-life-card-text {
  background: #ffffff;
  border-radius: 18px;
  width: 96%;
  bottom: 3px;
  margin: 0px 8px;
  position: absolute;
  box-shadow: 0 0 40px #c4c4c4;
}

.indv-offset-order-summay .ant-card {
  background-color: #f3f3f3;
  border-radius: 12px;
}

.indv-offset-order-summay .ant-card .ant-card-head {
  border-bottom: 2px solid #e0e0e0;
}

.indv-offset-order-summay .ant-card .ant-card-head .ant-card-head-title {
  font-size: 18px;
  font-weight: 600;
  color: #131313;
  padding: 20px 12px;
}

.indv-offset-order-summay .ant-card .ant-card-body {
  padding: 0px;
}

.offset-onetime-order-summery .select-onetime-card {
  font-size: 15px;
  font-weight: 600;
  color: #9f9f9f;
  padding: 10px 0px;
  text-transform: uppercase;
}

.offset-onetime-order-summery p {
  font-size: 16px;
  font-weight: 600;
  color: #636268;
}
.offset-onetime-order-summery .ord-total-imp {
  font-size: 14px;
  font-weight: 600;
  color: #636268;
}

.indv-offset-order-total-1 {
  background-color: #dee9c7;
}

.indv-offset-order-total-1 p {
  margin-bottom: 0;
}

.indv-offset-order-total-1 span {
  color: #88ac4c;
  font-weight: 600;
  font-size: 18px;
}

.indv-offset-order-total-1 span b {
  color: #121212;
  font-size: 16px;
  padding-left: 4px;
}

.indv-offset-order-total-2 p {
  color: #aaaaaa;
}

.indv-offset-order-total-2 span {
  color: #aaaaaa;
}

.indv-offset-order-total-2 span b {
  color: #121212;
}

.indv-offset-order-btn-buy {
  width: 100% !important;
  background-color: #dce5d4 !important;
  color: #9eb37f !important;
  border-radius: 8px !important;
  height: 48px !important;
  border: none !important;
  cursor: pointer !important;
}

.indv-offset-order-btn-buy-gift {
  width: 100% !important;
  background-color: #88ae47 !important;
  color: #fff !important;
  border-radius: 8px !important;
  height: 48px !important;
  border: none !important;
  cursor: pointer !important;
}

.indv-offset-order-btn-cart {
  width: 100% !important;
  background-color: #88ae47 !important;
  color: #ffffff !important;
  border-radius: 8px !important;
  height: 48px !important;
  border: none !important;
  cursor: pointer !important;
}

.indv-offset-order-summay-desc p {
  font-weight: 500;
  color: #aaaaaa;
  list-style: none;
}
.indv-offset-order-summay-desc ul li {
  font-weight: 500;
  color: #aaaaaa;
  list-style: none;
}
.indv-offset-order-summay-desc p span {
  color: #121212;
  font-weight: bold;
  padding: 0 5px;
}
.one-time-offset-sec .ant-row .ant-col .navbar .navbar-nav {
  flex-direction: row;
}

.one-time-offset-sec
  .ant-row
  .ant-col
  .navbar
  .navbar-nav
  .nav-item:not(:last-child) {
  margin-right: 69px;
}
.hidentext {
  display: none;
}

.login-indv-form
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector {
  border-radius: 8px;
}

.remove-overflow-x {
  overflow-x: hidden !important;
  margin-right: 0px !important;
}

.indv-offset-tab-link a {
  color: #aaaaaa !important;
}

.indv-offset-tab-link a.active {
  color: #000000 !important;
  border-bottom: solid 2px #88ae47 !important;
  padding: 0px 24px 19px 24px;
}

.idv-offset-top-menu ul {
  border-bottom: 1px solid #f0f0f0 !important;
  padding: 18px 0;
}
.idv-offset-top-menu ul li {
  display: block;
}
.indv-scrol-sec-subs {
  padding: 0 10px;
}

.indv-scrol-sec-subs p {
  margin-bottom: 0px !important;
  font-weight: 600;
  font-size: 16px;
  margin-left: 10px;
}

.indv-scrol-sec-subs img {
  width: 24px;
}

.indv-scrol-sec-subs-forward,
.indv-scrol-sec-subs-back {
  background: #eeeeee;
  margin: 0 2px;
  padding: 4px 10px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
}

.offset-onetime-car-sec {
  background-color: #fafaf8 !important;
  border: 1px solid #88ae47;
  border-radius: 12px;
}

.offset-onetime-car-sec p {
  margin-bottom: 0px;
  color: #797b7a;
}

.offset-onetime-car-sec-head {
  position: relative;
}

.offset-onetime-car-sec-head p {
  font-weight: 600;
  font-size: 15px;
}

.other-transport-tab {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  margin: 0;
}
.other-transport-tab li {
  display: block;
}

.other-transport-tab li {
  padding: 15px 30px;
  position: relative;
  font-weight: 600;
  color: #acacac;
  cursor: pointer;
}

.other-transport-tab li.active {
  border-bottom: 1px solid #88ac4c;
  color: #000000;
}

.offset-onetime-car-bottom-sec {
  border-top: 1px solid #f0f0f0 !important;
}

.offset-onetime-car-bottom-sec
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.offset-onetime-car-bottom-sec input {
  border-radius: 10px !important;
}

.offset-onetime-car-bottom-sec-form-border {
  border: solid 1px #ececea;
  border-radius: 12px;
}

.offset-onetime-car-bottom-sec2-form-border {
  border: solid 1px #ececea;
  border-radius: 12px;
  height: 100%;
}

.offset-onetime-car-bottom-sec-or {
  position: absolute;
  z-index: 99;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.offset-onetime-car-bottom-sec-or p {
  background: #ffffff;
  padding: 8px;
  border-radius: 24px;
  width: 36px;
  font-size: 13px;
  text-align: center;
  box-shadow: 1px 2px 8px 1px rgb(0 0 0 / 32%);
}

.offset-car-vehicle-detail {
  border-bottom: solid 1px #ececea;
}

.offset-car-vehicle-detail-head p {
  font-weight: 600;
  font-size: 15px;
}

.offset-car-vehicle-detail-btn button {
  background-color: #88ae47 !important;
  color: #ffffff !important;
  border-radius: 8px !important;
  height: 48px !important;
  border: none !important;
  font-weight: 500;
  width: 100%;
}

/* ---------------Offset Flight Tab css ----------------------- */
.carbon-offset-flight-sec {
  padding: 0px 20px 10px 20px;
  margin-top: -35px;
}

.carbon-offset-flight-sec .ant-card {
  border-radius: 14px;
}

.carbon-offset-flight-sec .ant-card-bordered {
  border: 2px solid #88ae47;
  background: #fafaf8;
}

.offset-flight-radio
  .ant-radio-wrapper-checked
  .ant-radio-checked
  .ant-radio-inner {
  border-color: #63959b;
}

.offset-flight-radio .ant-radio-wrapper {
  margin-right: 30px;
}

.offset-flight-radio
  .ant-radio-wrapper-checked
  .ant-radio-checked
  .ant-radio-inner::after {
  background-color: #63959b;
}

.offset-flight-radio .ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
  border-color: #63959b !important;
}

.flight-card-content {
  padding-top: 20px;
}

.flight-card-content
  .ant-select-single.ant-select-sm:not(.ant-select-customize-input)
  .ant-select-selector {
  padding: 13px 18px;
  height: 50px;
}

.flight-card-content .ant-row .ant-col p {
  font-size: 14px;
  color: #7d7d7d;
}

.flight-card-content
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border-radius: 10px;
}

.FlightRightLeft {
  width: 30px;
  height: 30px;
}

.pad-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.flight-card-content .flight-pass-data {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.flight-card-content .flight-pass-data .flight-img-card {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 6px;
  padding: 5px;
  margin: 0px 30px;
  cursor: pointer;
}

.offset-order-summery-label .flight-pass-data p {
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 95px;
}

.flight-card-content .flight-pass-data .flight-img-card img {
  width: 20px;
}

.flight-card-content .d-flex-center {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.carbon-offset-btn {
  background-color: #88ae47 !important;
  padding: 0px 56px !important;
  border-radius: 6px !important;
  color: #fff !important;
  height: 55px !important;
  float: right;
}

.flight-card-content .ant-btn:hover,
.ant-btn:focus {
  border-color: transparent !important;
}

/* ------------------------------------------------- */
.greentxt {
  color: #88ae47 !important;
}

.offset-mtp {
  margin-top: -15px !important;
}

.offset-order-summery-label {
  padding-top: 10px;
}

.offset-order-summery-label .ant-row .ant-col p {
  font-size: 14px;
  color: #7d7d7d;
}

.offset-order-summery-label .flight-pass-data {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.offset-order-summery-label .flight-pass-data .flight-img-card {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 6px;
  padding: 5px;
  cursor: pointer;
}

.offset-order-summery-label .mr-lft {
  margin-left: 30px;
}

.offset-order-summery-label .mr-rght {
  margin-right: 30px;
}

.offset-order-summery-label .flight-pass-data .flight-img-card img {
  width: 20px;
}

.offset-order-summery-label .d-flex-center {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.offset-onetime-household-sec-head p {
  font-weight: 600;
  font-size: 15px;
}

.offset-onetime-household-sec-text p {
  color: #9f9f9f;
}

.offset-house-btn-sec {
  border-top: solid 1px #ececea;
}

.other-transport-add-stage-btn button {
  background: none !important;
  color: #88ae47 !important;
  border: none;
  box-shadow: none;
  padding: 0 !important;
}

.other-transport-add-stage-btn button :hover {
  background: none;
  color: #88ae47;
}

.btn-img-span {
  width: 25px;
  padding-bottom: 2px;
}
.other-transport-remove-btn {
  padding-top: 8px;
  cursor: pointer;
}
.other-transport-remove-btn img {
  border: solid 1px #ececea;
  width: 25px;
  padding: 2px;
  border-radius: 50%;
}

.padding-t-b {
  padding: 20px 0;
}

.offset-flight-card {
  padding: 20px 0;
}

.offset-flight-card .ant-row .ant-col label {
  font-size: 18px;
  font-weight: 800;
}

.offset-flight-card .ant-row .ant-col span {
  font-size: 14px;
  color: #acacac;
  font-weight: 500;
  display: block;
  /* word-break: break-word; */
}

.offset-flight-card .flight-place-way img {
  background: #fff;
  padding: 8px;
  border-radius: 24px;
  width: 36px;
  font-size: 13px;
  text-align: center;
  box-shadow: 1px 2px 8px 1px rgb(0 0 0 / 32%);
  position: relative;
  top: -34px;
  left: 90px;
}
.off-ord-flight-data-card .ant-row .ant-col span {
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 15px 0;
}
.plant-tree-gift-card .ant-row .ant-col span {
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 0 !important;
}

.txt-algn-cntr {
  align-items: center;
}

.off-ord-flight-data-card .ant-row .ant-col .text-end {
  text-align: end;
}

.off-ord-flight-data-card .ant-row .text-end label {
  font-size: 15px;
  padding: 15px 0;
}

.offset-flight-card hr {
  margin-top: 25px;
}

.offset-flight-card .car-place-line {
  border-top: 1px dashed #acacac;
}

.car-place-line {
  border-top: 1px dashed #acacac;
}

.dsp-grid {
  display: grid !important;
}

.pb-15 {
  padding-bottom: 15px;
}
.select-validation {
  color: red;
}
.plant-tree-ord {
  /* padding-left: 20px; */
}
.plant-tree-ord ul li {
  list-style: none;
}
.txt-no-input {
  font-size: 16px;
  font-weight: 600;
  color: #78777c;
}
.Life_style_NoData {
  display: flex;
  justify-content: center;
}
.ant-popover-inner-content {
  width: 430px;
}
.have_gift-code {
  margin-bottom: 5px;
}
.gift-code-input {
  padding-top: 20px;
}
.gift_card_total {
  padding: 0 20px;
}
.second_cart_projects {
  background-color: #fff !important;
  margin-top: 20px !important;
}
.second_cart_projects .card_project_content {
  padding: 20px 25px 0px 20px;
}
.card_project_content img {
  width: 115px;
}
.card_project_content p {
  color: #acacac;
  padding-top: 15px;
  font-size: 16px;
}
.gift_apply_remove_btn {
  border-radius: 10px !important;
  height: 35px !important;
}
.text_capital {
  text-transform: capitalize;
}
.offset-top-bg img {
  width: 100%;
}
.validate_error {
  color: #ff4d4f;
  font-size: 14px;
  font-weight: 400;
}
.offset-cards-row {
  position: relative;
  bottom: 48px;
}

.off-set-car-img-sec-img img {
  width: 100%;
  max-height: 455px;
  min-height: 455px;
}

.off-set-flight-img-sec-img img {
  width: 100%;
  max-height: 385px;
  min-height: 385px;
}

.off-set-car-img-sec .head p {
  font-size: 20px !important;
  font-weight: 600 !important;
}

.off-set-car-img-sec .text p {
  font-size: 14px !important;
  color: #121212 !important;
  font-weight: 400 !important;
}

.off-set-car-img-sec .text2 p {
  font-size: 14px !important;
  color: #121212 !important;
  font-weight: 400 !important;
}
.font-text16 {
  font-size: 16px;
}
/* --------------Mobile Responsive Start css by Shlok */
@media screen and (min-width: 370px) and (max-width: 670px) {
  .one-time-offset-sec .ant-row {
    flex-flow: row wrap;
    /* display: block; */
  }
  .one-time-offset-sec .ant-col {
    width: 100%;
    margin: 5px 0px;
  }
  .one-time-offset-sec .ant-col-lg-21 {
    max-width: 100% !important;
  }
  .ant-card-head {
    margin-top: 20px;
  }
  .indv-offset-order-btn-buy {
    margin-bottom: 15px;
  }
  .carbon-offset-flight-sec {
    padding: 0;
  }
  .carbon-offset-onetime-life-sec {
    padding: 0;
  }
  .offset_down_heade_back {
    margin-top: 15px;
  }
  .offset-car-vehicle-detail-btn button span{
    display: flex;
    justify-content: center;
  }
  .business-detail-head{
    font-size: 20px;
    line-height: 30px;
  }
}

@media (min-width: 768px) and (max-width: 1366px) {
  .off-set-car-img-sec .head p {
    font-size: 18px !important;
  }

  .off-set-car-img-sec .text p {
    font-size: 13px !important;
  }

  .off-set-car-img-sec .text2 p {
    font-size: 13px !important;
  }
  .indv-offset-order-btn-buy {
    margin-bottom: 15px;
  }
  .carbon-offset-flight-sec {
    padding: 0;
  }
  .carbon-offset-onetime-life-sec {
    padding: 0;
  }
  .offset_down_heade_back {
    margin-top: 15px;
  }
}

@media only screen and (max-width: 1399px) {
  .offset-flight-card .flight-place-way img {
    left: 55px;
  }
  .indv-offset-order-btn-buy {
    margin-bottom: 15px;
  }
  .carbon-offset-flight-sec {
    padding: 0;
  }
  .carbon-offset-onetime-life-sec {
    padding: 0;
  }
  .offset_down_heade_back {
    margin-top: 15px;
  }
}
