@font-face {
    font-family: 'Roboto-Black';
    src: url('Roboto-Black.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto-BlackItalic';
    src: url('Roboto-BlackItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto-Bold';
    src: url('Roboto-Bold.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto-BoldItalic';
    src: url('Roboto-BoldItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto-Italic';
    src: url('Roboto-Italic.ttf') format('truetype');
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto-Light';
    src: url('Roboto-Light.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto-LightItalic';
    src: url('Roboto-LightItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto-Medium';
    src: url('Roboto-Medium.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto-MediumItalic';
    src: url('Roboto-MediumItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto-Regular';
    src: url('Roboto-Regular.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto-Thin';
    src: url('Roboto-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto-ThinItalic';
    src: url('Roboto-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}



