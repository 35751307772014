.indv-add-cart-sec .ant-collapse > .ant-collapse-item {
  background: #ffffff !important;
  border: none;
  margin-bottom: 10px;
  border-radius: 10px;
  box-shadow: 0px 0px 13px 5px rgba(0, 0, 0, 0.12);
  padding: 8px 0 !important;
}

.indv-add-cart-sec .ant-collapse {
  background-color: none !important;
  border: none !important;
}

.indv-add-cart-sec .ant-collapse > .ant-collapse-item:last-child,
.ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
  border-radius: 10px !important;
}

.indv-add-cart-sec .ant-collapse-content {
  border-top: unset !important;
}
.indiv_add_cart_table .product-label-tbl {
  display: flex;
}
.indiv_add_cart_table .product-label-tbl p {
  font-weight: 800;
  padding-left: 5px;
  padding-top: 10px;
}
.indiv_add_cart_table .product-label-imgLbl-tbl {
  display: flex;
  align-items: center;
}
.indiv_add_cart_table .product-label-imgLbl-tbl p {
  font-weight: 800;
  padding-top: 10px;
}
.indiv_add_cart_table .product-label-imgLbl-tbl img {
  width: 50px;
  height: 50px;
  border-radius: 6px;
}
.indiv_add_cart_table .ant-collapse-header {
  color: #2a2a2a;
  font-size: 18px;
  font-weight: 900;
}
.indiv_add_cart_table .ant-table-thead > tr > th {
  position: relative;
  color: #ababab;
  font-weight: 600;
  background: transparent;
  border-bottom: none;
}
.indiv_add_cart_table
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  background-color: transparent;
}
.indv-cart-btn {
  width: 250px;
  background-color: #88ae47 !important;
  color: #ffffff !important;
  border-radius: 8px !important;
  height: 48px !important;
  border: none !important;
  margin-top: 15px;
}
.cart-border-top {
  border-top: 1px solid #ababab;
}
.indiv_add_cart_table .ant-collapse-content > .ant-collapse-content-box {
  padding: 0;
}
.indiv_add_cart_table .cart-border-top {
  padding: 16px;
}
.tble-circle-img {
  cursor: pointer;
}
.cart-radio-button {
  display: flex;
  flex-direction: column;
}
.cart-radio-button .ant-radio-wrapper .ant-radio-inner {
  border-color: #629e9f;
  width: 20px;
  height: 20px;
  border-width: 3px;
}
.cart-radio-button .ant-radio-wrapper .ant-radio-inner::after {
  background-color: #629e9f;
}
.payment-method-input {
  display: flex;
  flex-direction: column;
}
.payment-method-input .ant-form-item {
  margin-bottom: 0;
}
.payment-method-input .ant-input {
  border-radius: 8px;
  height: 50px;
}
.payment-method-input .ant-input-lg {
  padding: 6.5px 15px;
}
.cart-border {
  border-top: 1px solid #ababab;
  margin: 15px 0;
}
.debit-credit-card {
  padding: 20px 25px;
}
.debit-credit-card .ant-card {
  border-radius: 8px !important;
}
.debit-credit-card .ant-card .ant-card-body {
  padding: 0;
}
.payment-method-input .ant-radio-wrapper {
  align-items: center;
}
.saved-card-details {
  display: grid;
  padding-left: 10px;
}
.saved-card-details-img img {
  width: 35px;
}
.saved-card-details p {
  margin-bottom: 0;
}

.custom-card-detail-enter .sc-bczRLJ {
  width: 100% !important;
}

.custom-card-detail-enter .sc-gsnTZi {
  height: 50px !important;
  border-radius: 8px !important;
  border: 1px solid #d9d9d9 !important;
}
.custom-card-detail-enter #cardNumber {
  width: 75% !important;
}
.padding-r {
  padding-right: 15rem;
}
.trashCard img{
  width: 20px;
  cursor: pointer;
}

.pay_btn{
  background: var(--color-individual) !important;
  color: #fff !important;
}