.Bussiness_project_distribution {
  /* width: 50%; */
  height: 94%;
  box-shadow: 0px 0px 16px -3px rgb(0 0 0 / 51%);
  border-radius: 12px;
  margin-top: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.one_more {
  display: block;
}

.Bussiness_Impact_Offset {
  /* width: 49%; */
  height: 94%;
  box-shadow: 0px 0px 16px -3px rgb(0 0 0 / 51%);
  /* margin-left: 10px; */
  border-radius: 12px;
  margin-top: 22px;
}

.Bussiness_project_distribution_h {
  font-family: "Roboto-Bold";
  font-weight: bold;
  padding-left: 24px;
  padding-top: 18px;
  font-size: 20px;
}

.Bussiness_project_distribution_inner_part1 {
  display: flex;
  height: 100px;
  align-items: center;
  justify-content: space-between;
  width: 94%;
}

.no-color{
  color: unset !important;
}

.Bussiness_project_distribution_inner_imagePart {
  display: flex;
  align-items: center;
  padding-left: 24px;
}

.Bussiness_project_distribution_inner_part_imagePart_Main {
  width: 43%;
  height: 40px;
}

.Bussiness_project_distribution_inner_part_imagePart_Main_SubImage {
  width: 37%;
}

.Bussiness_project_distribution_inner_part_image_sideArrow {
  width: 82%;
  height: 21px;
}
.Bussiness_project_distribution_inner_part {
  height: 101px;
  box-shadow: 0px 0px 16px -3px rgb(0 0 0 / 51%);
  /* margin-top: 14px; */
  border-radius: 12px;
}
.Bussiness_project_distribution_inner_imagePart_P {
  width: 160px;
  padding-left: 10px;
  font-family: "Roboto-Bold";
  font-size: 16px;
}

.bussiness_graph_height {
  height: 275px !important;
  padding: 22px;
}
.login-text-span {
  color: var(--color-individual);
  font-family: Roboto-Bold;
  padding-right: 4px;
}
.Business_Header_Center_empty{
  height: 300px;
}