@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@font-face {
    font-family: 'Roboto-Black';
    src: url(/static/media/Roboto-Black.f3fa86ed.ttf) format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto-BlackItalic';
    src: url(/static/media/Roboto-BlackItalic.9f20f498.ttf) format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto-Bold';
    src: url(/static/media/Roboto-Bold.e31fcf18.ttf) format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto-BoldItalic';
    src: url(/static/media/Roboto-BoldItalic.b03c5eb4.ttf) format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto-Italic';
    src: url(/static/media/Roboto-Italic.549ec357.ttf) format('truetype');
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto-Light';
    src: url(/static/media/Roboto-Light.46e48ce0.ttf) format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto-LightItalic';
    src: url(/static/media/Roboto-LightItalic.4356190d.ttf) format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto-Medium';
    src: url(/static/media/Roboto-Medium.894a2ede.ttf) format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto-MediumItalic';
    src: url(/static/media/Roboto-MediumItalic.95d76465.ttf) format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto-Regular';
    src: url(/static/media/Roboto-Regular.df7b648c.ttf) format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto-Thin';
    src: url(/static/media/Roboto-Thin.94998475.ttf) format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto-ThinItalic';
    src: url(/static/media/Roboto-ThinItalic.8f066370.ttf) format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}




.idv-login-modal .ant-modal-header {
  border-bottom: 0px !important;
  padding: 16px 24px 0px !important;
}

.idv-login-modal .ant-modal-header .ant-modal-title {
  font-weight: 600;
  font-size: 15px;
}
.idv-login-modal .ant-modal-content {
  border-radius: 12px;
  padding: 0;
}

.login-indv-form-up-txt p {
  margin-bottom: 0px;
}
.idv-login-modal
  .ant-modal-content
  .ant-modal-body
  .ant-row
  .ant-col
  .idv-login-modal-content
  .title {
  font-size: 18px;
  font-weight: 600;
  margin-top: -10px;
  margin-bottom: 12px;
}
.idv-login-modal .ant-modal-content .ant-modal-close-x .anticon {
  color: #000000;
}
.login-indv-form-up-txt p a {
  color: #88ae47 !important;
  padding-left: 5px;
}
.indivibusinessModal {
  height: 100%;
  margin: 0px !important;
  padding-top: 45px;
}
.indivibusinessModal img {
  height: 100% !important;
}
.ant-statistic {
  text-align: center;
}
.login-indv-form input,
.login-indv-form .ant-input-password {
  border-radius: 8px;
}
.login-indv-form .inv-create-pass {
  padding: 40px 0;
}
.indv-login-form-btn-sec {
  justify-content: space-between;
  align-items: center;
}
.indv-login-form-btn-sec .ant-row .ind-web-span a {
  color: #88ae47;
  font-weight: 500;
}
.indv-login-form-btn-sec .ant-row .ind-web-span-lft a {
  color: #88ae47;
  font-weight: 500;
  padding-left: 8px;
}
.ind-web-span-lft {
  padding-left: 22px;
}
.carbon-idv-signin-btn {
  background-color: #88ae47 !important;
  padding: 0px 56px !important;
  border-radius: 6px !important;
  color: #fff !important;
  height: 48px !important;
}

.carbon-idv-signin-social-sec hr {
  width: 36%;
}

.carbon-idv-signin-social-sec span {
  font-size: 12px;
}

.idv-login-g-btn button {
  background-color: #ffffff;
  color: #eb3e37;
  border: none;
  width: 100%;
  box-shadow: 0px 0px 19px -9px rgba(0, 0, 0, 0.86);
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 48px;
  border-radius: 8px;
  font-weight: 600;
}

.idv-login-f-btn button {
  background-color: #ffffff;
  border: none;
  color: #39507e;
  width: 100%;
  box-shadow: 0px 0px 19px -9px rgba(0, 0, 0, 0.86);
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 48px;
  border-radius: 8px;
  font-weight: 600;
}

.indv-auth-img img {
  width: 100%;
  border-radius: 10px;
  height: 485px;
}
.idv-login img {
  width: 100%;
  border-radius: 10px;
  height: 475px;
}
.idv-login-page img {
  width: 100%;
  border-radius: 10px;
  height: 445px;
}
.indv-auth-create-pass img {
  width: 100%;
  border-radius: 10px;
  height: 430px;
}
.otp_expired_msg {
  color: red;
  display: flex;
  justify-content: center;
}
.indi_bus_Modal {
  top: 65px !important;
}
.indi_bus_Modal .login-indv-form-up-txt {
  margin-bottom: 1rem;
}
.indi_bus_Modal .idv-login-modal-content .indi_bus_form p {
  margin-bottom: 10px;
}
.indi_bus_Modal .idv-login-modal-content .indi_bus_form .ant-form-item {
  margin-bottom: 12px;
}
@media screen and (max-width: 600px) {
  .fix-display{
    display: flex;
    white-space: nowrap;
    flex-wrap: wrap;
  }
  .carbon-idv-signin-social-sec span {
    font-size: 12px;
    white-space: nowrap;
    padding: 0px 10px;
}
}
@media screen and (min-width: 768px) and (max-width: 1000px) {
  .indv-auth-img img {
    height: auto;
    margin-top: 20px;
  }
  .idv-login-g-btn button {
    width: 150px;
  }
  .idv-login-f-btn button {
    width: 150px;
  }
  .login-indv-form .inv-create-pass {
    padding: 40px 0;
  }
  .idv-login-g-btn .fa-brands {
    padding-left: 10px;
  }
  .idv-login-f-btn .fa-brands {
    padding-left: 10px;
  }
}
@media screen and (min-width: 425px) and (max-width: 769px) {
  .indv-auth-img img {
    height: auto;
    margin-top: 20px;
  }
  .idv-login-g-btn .g_btn {
    width: 150px;
    padding: 0 30px;
  }
  .idv-login-f-btn .f_btn {
    width: 150px;
    padding: 0 30px;
  }
  .login-indv-form .inv-create-pass {
    padding: 40px 0;
  }
  .idv-login img {
    height: auto;
  }
  .idv-login-g-btn .fa-brands {
    padding-left: 10px;
  }
  .idv-login-f-btn .fa-brands {
    padding-left: 10px;
  }
}
@media screen and (min-width: 370px) and (max-width: 670px) {
  .idv-login-g-btn .g_btn {
    width: 150px;
    padding: 0 30px;
  }
  .idv-login-f-btn .f_btn {
    width: 150px;
    padding: 0 30px;
  }
  .idv-login-g-btn .fa-brands {
    padding-left: 10px;
  }
  .idv-login-f-btn .fa-brands {
    padding-left: 10px;
  }
}
.idv-modal-upper-sec-back button {
  background: #ededed !important;
  border: none;
  border-radius: 15px;
  padding: 4px 12px;
}

.carbon-idv-forgot-btn {
  width: 100%;
}

.indv-pass-success-modal {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
}

.indv-pass-success-modal-check .fas {
  background: #f2f2f2 !important;
  padding: 28px !important;
  border: solid 3px #88ae47 !important;
  border-radius: 56px !important;
  font-size: 40px;
  color: #88ae47;
}

.indv-pass-success-modal-btn button {
  background-color: #88ae47 !important;
  border: none !important;
  padding: 0px 56px !important;
  border-radius: 6px !important;
  color: #fff !important;
  height: 48px !important;
}

.forgot-pass-idv-otp-enter-sec {
  text-align: center;
  padding: 60px 0;
}

.forgot-pass-idv-otp-enter-sec input {
  width: 60px !important;
  height: 55px !important;
  margin-right: 8px !important;
}

.ind-web-resend span {
  color: #88ae47 !important;
  font-weight: 500;
  cursor: pointer;
}
.idv-otp-form {
  bottom: -23px;
  position: absolute;
  width: 410px;
}
.create-new-pass-padding {
  padding: 70px 0;
}
@media screen and (min-width: 425px) and (max-width: 769px) {
  .forgot-pass-idv-otp-enter-sec input {
    width: 50px !important;
    height: 45px !important;
    margin-right: 8px !important;
  }
  .idv-otp-form {
    width: 360px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1000px) {
  .forgot-pass-idv-otp-enter-sec {
    padding: 50px 0;
  }
  .idv-otp-form {
    width: 340px;
  }
}

.auth-bg {
  background: url("https://minio.b120578.dev.eastus.az.svc.builder.cafe/sbucket/variants/k64fzey62bj1y9cz7qgckdnqhr86/6f7daa6c86a9bc2c53f5d0684447f253adfa8f13401d95f9d66464245ed9ff2e?response-content-disposition=inline%3B%20filename%3D%221eeaff1adb2ac227e5c20751c15edde4f86081b8.jpg%22%3B%20filename%2A%3DUTF-8%27%271eeaff1adb2ac227e5c20751c15edde4f86081b8.jpg&response-content-type=image%2Fjpeg");
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
}
.term-cond-btn-foot-signup {
  background: #88ae47 !important;
  color: #fff !important;
  width: 125px;
  border-radius: 6px !important;
}
.idv-signup-modal-term-condition{
  top: 10px !important;
}
.idv-signup-modal-term-condition .ant-modal-content {
  border-radius: 10px;
}
.privacy_height{
  max-height: 185px;
  overflow-y: auto;
}
.privacy_height_adjust::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.privacy_height_adjust::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5;
}

.privacy_height_adjust::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #e0e0e0;
}
.privacy_height_adjust{
  height: 500px;
  overflow: scroll;
  overflow-x: hidden;
}
.inlinePera p:last-child{
  display: inline;
 }
.f-w6 {
  font-weight: 600;
  padding: 0 5px;
}
.term-cond-signup-head p {
  font-size: 18px;
  font-weight: 800;
  padding-bottom: 10px;
}
.padding-data-ui{
  padding: 108px 0;
}
.carbon_footer_bar {
  background-color: #252525 !important;
  min-height: 200px;
  border-bottom: solid 1px #252525;
}

.carbon_footer_bar .container {
  padding-top: 40px;
}

nav {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  color: #000000;
  position: relative;
  z-index: 99;
  min-height: 72px;
}

.fas {
  cursor: pointer;
}

.logo {
  font-weight: 600;
  font-size: 25px;
  color: tomato;
}

.logo font {
  color: #fff;
}

.footer-nav-sec ul {
  padding: 0;
}

.footer-list {
  list-style-type: none;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.footer-list li a {
  text-decoration: none;
  color: #ffff;
  font-weight: 500;
}
.footer_hr {
  border-top: 1px solid #7d7c81;
  margin-top: 40px;
}
.footer_ul_icons {
  display: flex;
  align-items: center;
  list-style-type: none;
}
.footer_icons {
  display: flex;
  padding-top: 15px;
  padding-bottom: 20px;
  justify-content: space-between;
}
.footer_icons .logo img {
  width: 110px;
}
.footer_icons h4 {
  color: #828186;
  font-size: 12px;
  display: flex;
  align-items: center;
}

.footer_ul_icons {
  padding-left: 0;
}

.footer_ul_icons li {
  padding: 5px 10px;
  margin-left: 5px;
}
.footer_ul_icons li img {
  width: 25px;
  cursor: pointer;
}
.footer_ul_icons li .fb_icon {
  width: 12px;
}

.footer_icons_text {
  display: flex;
  text-align: center;
}
@media screen and (max-width: 800px) {
  .logo {
    font-size: 20px;
  }
  .footer-list li a {
    font-size: 14px;
    margin-left: 10px;
  }
}

@media screen and (max-width: 600px) {
  .footer-list {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .footer-list li {
    margin-bottom: 20px;
  }

  .footer-list li:last-child {
    margin-bottom: 0px;
  }

  .footer_icons {
    display: flex;
    flex-direction: column;
  }

  .footer_icons .logo {
    display: flex;
    justify-content: center;
  }

  .footer_icons_text {
    display: flex;
    justify-content: center;
    margin: 25px 0;
  }
}

@media screen and (max-width: 769px) {
  .footer_ul_icons {
    display: flex;
    justify-content: center;
    padding-left: 0;
  }
}

.carbon-navbar .logo img {
  width: 155px;
}
.project_footer {
  color: #fff;
}

.carbon-navbar {
  background-color: #f4f4f4 !important;
  min-height: 72px;
  border-bottom: solid 1px #d0d0d0;
}

.carbon-navbar-our-approach{
  background: none !important;
  min-height: 72px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  position: absolute;
  width: 100%;
}

/* .carbon-nav {
  flex-grow: unset !important;
}

.carbon-nav .nav-link {
  color: #000000 !important;
} */

nav {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  color: #000000;
  position: relative;
  z-index: 99;
  min-height: 72px;
}

.fas {
  cursor: pointer;
}

.menu-icon {
  display: none;
  font-size: 20px;
}
.menu-icon .fa-times {
  transform: rotate(360deg);
  transition: all 0.2s ease-in-out;
}

.menu-icon .fa-bars {
  transform: rotate(180deg);
  transition: all 0.2s ease-in-out;
}

.logo {
  font-weight: 600;
  font-size: 25px;
  color: tomato;
}

.logo font {
  color: #fff;
}

.menu-list {
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* .menu-list li:last-child a {
  font-weight: 300;
  padding: 5px 10px;
  background-color: #01aacd;
  transition: all 0.5s ease-in-out;
  margin-left: 20px;
  border-radius: 2px;
} */

.menu-list li a {
  text-decoration: none;
  color: #000000;
  font-weight: 500;
  padding: 5px 10px;
  margin-left: 20px;
}

/* .menu-list li a:hover,
.menu-list li a.active {
  background-color: #fff;
  border-radius: 2px;
  color: #000;
  transition: all 0.5s ease-in-out;
} */

@media screen and (max-width: 800px) {
  .logo {
    font-size: 20px;
  }
  .menu-list li a {
    font-size: 14px;
    margin-left: 10px;
  }
}

@media screen and (max-width: 768px) {
  .menu-icon {
    display: block;
  }
  .menu-list {
    flex-direction: column;
    width: 100%;
    position: fixed;
    background: #f4f4f4;
    padding: 20px;
    right: 0;
    top: 64px;
    transition: all 0.2s ease-in-out;
  }

  .menu-list li {
    margin-bottom: 20px;
    width: 100%;
    text-align: center;
  }
  .menu-list li:last-child {
    margin-bottom: 0px;
  }

  .menu-list li a,
  .menu-list li:last-child a {
    /* width: 100px; */
    display: block;
    margin: auto;
  }

  .menu-list.close {
    right: -100%;
    transition: all 0.5s ease-in-out;
  }
}

.carbon-navbar .logo img {
  width: 155px;
  cursor: pointer;
}


.carbon-navbar-our-approach .logo img {
  width: 155px;
  cursor: pointer;
}

.header-eko-dropdown{
  padding: 6px 10px !important;
}
.ant-dropdown-open .dropdown_img{
display: none;
}
.updown_img{
  position: absolute !important;
  display: none !important;
  top: 3px;
  right: 0px;
}
.dropdown_img{
  position: absolute !important;
  /* display: none; */
  top: 3px;
  right: 0px;
}
.drop_down_hover:hover .updown_img{
  position: absolute;
  /* top: 27px; */
  display: block !important;
}
.drop_down_hover:hover .dropdown_img{
  position: absolute;
  /* top: 27px; */
  display: none !important;
}

.hover_div{
  position: absolute !important;
  width: 195px;
  padding: 16px 0px;
  top: 25px;
  left: -6px;
  background: white;
  display: none !important ;
}
.drop_down_hover:hover .hover_div {
  display: block !important;
}
.hover_div a {
  padding: 0 !important;
  margin-left: 0 !important;
}

.hover_div hr{
  margin: 1rem 0 !important;
  color: #838383 !important;
}

.hover-div-dropdown-a{
  display: flex;
  justify-content: flex-start !important;
  margin-left: 1.5rem;
}
/* **************.Logo Container css******************** */

.indv-logo-head img {
  width: 116.7px !important;
  cursor: pointer !important;
}
.ant-table-pagination.ant-pagination {
  display: none;
}
.class-pag {
  display: flex;
  justify-content: flex-end;
  padding-top: 15px;
}

.selected {
  font-weight: bolder;
  margin: 0px 12px !important;
  padding: 0px 8px !important;
  border: 1px solid var(--color-individual) !important;
}
.next .disabled .previous .disabled {
  color: black;
}
/* .previous {
  color: var(--color-individual) !important;
} */
.break {
  border: none !important;
}
/* **************.NavLeftContainer css******************** */

.NavLeftContainer {
  display: flex;
}

.NavFaq {
  cursor: pointer;
  color: var(--color-dark-grey);
  padding: 0px 14px 0px 0px;
  border-right: 2px solid var(--color-gray);
}

.LogiNavFaqnBtn {
  cursor: pointer;
  color: var(--color-dark-grey);
}
.CartImage {
  cursor: pointer;
  width: 50px;
  height: 25px;
  border-radius: 50%;
  padding: 0px 10px 0px 14px;
}
.CartImage_cart {
  cursor: pointer;
  width: 50px;
  height: 25px;
  padding: 0px 10px 0px 14px;
}
.icon {
  width: 200px;
  height: 200px;
}
.NavLeftContainer-avtar {
  display: flex;
  align-items: center;
}

.NavLeftContainer-sec1 {
  display: flex;
  align-items: center;
}

.NavLeftContainer-sec1 p,
.NavLeftContainer-avtar p {
  margin-bottom: 0 !important;
}

.NavLeftContainer-avtar .indv-dash-nav-dropdown {
  color: #808080 !important;
  align-items: center;
  display: flex;
}
.NavLeftContainer-avtar .ant-dropdown-trigger > .anticon.anticon-down,
.ant-dropdown-link > .anticon.anticon-down,
.ant-dropdown-button > .anticon.anticon-down {
  padding-left: 4px;
}
.indv-dash-nav-dropdown-text {
  padding: 16px 56px 16px 14px !important;
  border-radius: 12px !important;
}

.indv-dash-nav-dropdown-text .ant-dropdown-menu-item:hover {
  background-color: unset !important;
  color: #88ae47 !important;
}
.item_cart_main {
  position: relative;
}
.item_cart {
  position: absolute;
  top: -7px;
  right: -3px;
  color: #fff;
  font-weight: 700;
  background: red;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  text-align: center;
}

.indv-member-since {
  margin-right: 3rem;
}

.indv-member-since p {
  font-size: 15px;
  font-weight: 500;
  color: #88ae47;
}
.indv-member-since p span {
  color: rgb(124, 129, 136);
  font-weight: 400;
  padding-left: 8px;
}
@media screen and (min-width: 370px) and (max-width: 670px) {
  .indv-member-since {
    margin-right: 1rem;
  }

  .indv-member-since p span {
    display: inline-block;
  }
}
@media (min-width: 768px) and (max-width: 1366px) {
  .indv-member-since {
    margin-right: 2rem;
  }

  .indv-member-since p span {
    display: inline-block;
  }
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector {
  padding: 6px 11px !important;
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector {
  height: 50px !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 10px !important;
}

.offset-onetime-order-summery .ant-input {
  height: 50px !important;
  border-radius: 10px !important;
}
.not_edit{
  cursor: not-allowed;
}
.gift-code-input .ant-input {
  height: 40px !important;
}
.gift-code-input .ant-input-affix-wrapper {
  border-radius: 10px !important;
}

.ant-input:focus,
.ant-input-focused {
  border-color: #c6c6c6 !important;
  box-shadow: none !important;
}

.ant-input:hover {
  border-color: #c6c6c6 !important;
}

.admin_carbon_offset
  .ant-tabs
  .ant-tabs-nav
  .ant-tabs-nav-wrap
  .ant-tabs-nav-list
  .ant-tabs-tab.ant-tabs-tab-active
  .ant-tabs-tab-btn {
  color: #121212;
  font-size: 15px;
}

.admin_carbon_offset
  .ant-tabs
  .ant-tabs-nav
  .ant-tabs-nav-wrap
  .ant-tabs-nav-list
  .ant-tabs-tab {
  color: #b0b0b0;
  font-size: 15px;
}

.admin_carbon_offset
  .ant-tabs
  .ant-tabs-nav
  .ant-tabs-nav-wrap
  .ant-tabs-nav-list
  .ant-tabs-tab
  + .ant-tabs-tab {
  margin: 0 0 0 80px;
}

.admin_carbon_offset {
  width: 100%;
  z-index: 9;
}

.admin_carbon_offset .ant-tabs-nav-wrap {
  justify-content: center !important;
}
.subscription-disable-slider{
  position: relative;
}
.subscription-disable-slider .rec-arrow-right:disabled, .subscription-disable-slider .rec-arrow-left:disabled{
  cursor: not-allowed;
  display: none;
}

.subscription-disable-slider .rec-arrow-right {
  position: absolute;
  top: 0px;
  right: 20px;
  border: 0 !important;
  box-shadow: none;
  font-size: 14px;
  width: 25px;
  height: 25px;
  min-width: unset;
  border-radius: 5px;
  z-index: 999;
  cursor: pointer;
  background: #ececec;
  color: #706e6e;
  line-height: 25px;
}
.subscription-disable-slider .rec-arrow-left {
  position: absolute;
  top: 0px;
  right: 52px;
  border: 0 !important;
  box-shadow: none;
  font-size: 14px;
  width: 25px;
  height: 25px;
  min-width: unset;
  border-radius: 5px;
  z-index: 999;
  cursor: pointer;
  background: #ececec;
  color: #706e6e;
  line-height: 25px;
}

.subscription-disable-slider .rec-arrow-right:hover, .subscription-disable-slider .rec-arrow-right:hover:enabled, .subscription-disable-slider .rec-arrow-right:focus:enabled{
  border: 0 !important;
  box-shadow: none;
  background: #ececec !important;
  background-color: #ececec !important;
  color: #706e6e !important;
}

.carbon-offset-onetime-img-card {
  background: #fff;
  padding: 12px 8px 28px 15px;
  box-shadow: 0px 0px 16px -3px rgb(0 0 0 / 51%) !important;
  min-width: 110px !important;
  max-width: 110px !important;
  border-radius: 8px;
  height: 100%;
  cursor: pointer;
}
.carbon-business-offset-onetime-img-card {
  background: #fff;
  padding: 12px 8px 0px 15px;
  box-shadow: 0px 0px 16px -3px rgb(0 0 0 / 51%) !important;
  min-width: 110px !important;
  max-width: 110px !important;
  border-radius: 8px;
  height: 100%;
  cursor: pointer;
}

.active-bg-grey-onetime-card {
  background: #f6ffea !important;
  border: 0.5px solid #88ae47 !important;
}

.custom-pad-offset-onetime-card {
  padding: 12px 8px 5px 15px !important;
}

.custom-pad-offset-onetime-card img {
  height: 24px !important;
  width: 20px !important;
}

.carbon-offset-onetime-img-card p {
  color: #30302e;
  font-weight: 500;
  margin: 8px 0;
  font-size: 15px;
}
.carbon-business-offset-onetime-img-card p {
  color: #30302e;
  font-weight: 500;
  margin: 8px 0;
  font-size: 15px;
}

.carbon-offset-onetime-img-card img {
  width: 24px;
}
.carbon-business-offset-onetime-img-card img {
  width: 24px;
}

.carbon-offset-onetime-life-card-text-head p {
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 0;
}

.carbon-offset-onetime-life-card-text-head img {
  width: 22px;
  height: 22px;
}

.carbon-offset-onetime-life-card-img img {
  width: 100%;
  border-radius: 22px;
  cursor: pointer;
  height: 270px;
}

.life-card-img-select {
  border: 3px solid #88ae47;
  border-radius: 24px;
  height: 270px;
}

.carbon-offset-onetime-life-sec {
  padding: 15px 10px 10px 10px;
}

.carbon-offset-bg-img {
  margin-top: -40px;
}
.carbon-offset-onetime-life-sec-card {
  margin-top: 15px;
  position: relative;
}

.carbon-offset-flight-sec
  .ant-card
  .ant-card-head
  .ant-card-head-wrapper
  .ant-card-head-title {
  font-weight: 900;
}

.carbon-offset-onetime-life-card-text {
  background: #ffffff;
  border-radius: 18px;
  width: 96%;
  bottom: 3px;
  margin: 0px 8px;
  position: absolute;
  box-shadow: 0 0 40px #c4c4c4;
}

.indv-offset-order-summay .ant-card {
  background-color: #f3f3f3;
  border-radius: 12px;
}

.indv-offset-order-summay .ant-card .ant-card-head {
  border-bottom: 2px solid #e0e0e0;
}

.indv-offset-order-summay .ant-card .ant-card-head .ant-card-head-title {
  font-size: 18px;
  font-weight: 600;
  color: #131313;
  padding: 20px 12px;
}

.indv-offset-order-summay .ant-card .ant-card-body {
  padding: 0px;
}

.offset-onetime-order-summery .select-onetime-card {
  font-size: 15px;
  font-weight: 600;
  color: #9f9f9f;
  padding: 10px 0px;
  text-transform: uppercase;
}

.offset-onetime-order-summery p {
  font-size: 16px;
  font-weight: 600;
  color: #636268;
}
.offset-onetime-order-summery .ord-total-imp {
  font-size: 14px;
  font-weight: 600;
  color: #636268;
}

.indv-offset-order-total-1 {
  background-color: #dee9c7;
}

.indv-offset-order-total-1 p {
  margin-bottom: 0;
}

.indv-offset-order-total-1 span {
  color: #88ac4c;
  font-weight: 600;
  font-size: 18px;
}

.indv-offset-order-total-1 span b {
  color: #121212;
  font-size: 16px;
  padding-left: 4px;
}

.indv-offset-order-total-2 p {
  color: #aaaaaa;
}

.indv-offset-order-total-2 span {
  color: #aaaaaa;
}

.indv-offset-order-total-2 span b {
  color: #121212;
}

.indv-offset-order-btn-buy {
  width: 100% !important;
  background-color: #dce5d4 !important;
  color: #9eb37f !important;
  border-radius: 8px !important;
  height: 48px !important;
  border: none !important;
  cursor: pointer !important;
}

.indv-offset-order-btn-buy-gift {
  width: 100% !important;
  background-color: #88ae47 !important;
  color: #fff !important;
  border-radius: 8px !important;
  height: 48px !important;
  border: none !important;
  cursor: pointer !important;
}

.indv-offset-order-btn-cart {
  width: 100% !important;
  background-color: #88ae47 !important;
  color: #ffffff !important;
  border-radius: 8px !important;
  height: 48px !important;
  border: none !important;
  cursor: pointer !important;
}

.indv-offset-order-summay-desc p {
  font-weight: 500;
  color: #aaaaaa;
  list-style: none;
}
.indv-offset-order-summay-desc ul li {
  font-weight: 500;
  color: #aaaaaa;
  list-style: none;
}
.indv-offset-order-summay-desc p span {
  color: #121212;
  font-weight: bold;
  padding: 0 5px;
}
.one-time-offset-sec .ant-row .ant-col .navbar .navbar-nav {
  flex-direction: row;
}

.one-time-offset-sec
  .ant-row
  .ant-col
  .navbar
  .navbar-nav
  .nav-item:not(:last-child) {
  margin-right: 69px;
}
.hidentext {
  display: none;
}

.login-indv-form
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector {
  border-radius: 8px;
}

.remove-overflow-x {
  overflow-x: hidden !important;
  margin-right: 0px !important;
}

.indv-offset-tab-link a {
  color: #aaaaaa !important;
}

.indv-offset-tab-link a.active {
  color: #000000 !important;
  border-bottom: solid 2px #88ae47 !important;
  padding: 0px 24px 19px 24px;
}

.idv-offset-top-menu ul {
  border-bottom: 1px solid #f0f0f0 !important;
  padding: 18px 0;
}
.idv-offset-top-menu ul li {
  display: block;
}
.indv-scrol-sec-subs {
  padding: 0 10px;
}

.indv-scrol-sec-subs p {
  margin-bottom: 0px !important;
  font-weight: 600;
  font-size: 16px;
  margin-left: 10px;
}

.indv-scrol-sec-subs img {
  width: 24px;
}

.indv-scrol-sec-subs-forward,
.indv-scrol-sec-subs-back {
  background: #eeeeee;
  margin: 0 2px;
  padding: 4px 10px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
}

.offset-onetime-car-sec {
  background-color: #fafaf8 !important;
  border: 1px solid #88ae47;
  border-radius: 12px;
}

.offset-onetime-car-sec p {
  margin-bottom: 0px;
  color: #797b7a;
}

.offset-onetime-car-sec-head {
  position: relative;
}

.offset-onetime-car-sec-head p {
  font-weight: 600;
  font-size: 15px;
}

.other-transport-tab {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  margin: 0;
}
.other-transport-tab li {
  display: block;
}

.other-transport-tab li {
  padding: 15px 30px;
  position: relative;
  font-weight: 600;
  color: #acacac;
  cursor: pointer;
}

.other-transport-tab li.active {
  border-bottom: 1px solid #88ac4c;
  color: #000000;
}

.offset-onetime-car-bottom-sec {
  border-top: 1px solid #f0f0f0 !important;
}

.offset-onetime-car-bottom-sec
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.offset-onetime-car-bottom-sec input {
  border-radius: 10px !important;
}

.offset-onetime-car-bottom-sec-form-border {
  border: solid 1px #ececea;
  border-radius: 12px;
}

.offset-onetime-car-bottom-sec2-form-border {
  border: solid 1px #ececea;
  border-radius: 12px;
  height: 100%;
}

.offset-onetime-car-bottom-sec-or {
  position: absolute;
  z-index: 99;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.offset-onetime-car-bottom-sec-or p {
  background: #ffffff;
  padding: 8px;
  border-radius: 24px;
  width: 36px;
  font-size: 13px;
  text-align: center;
  box-shadow: 1px 2px 8px 1px rgb(0 0 0 / 32%);
}

.offset-car-vehicle-detail {
  border-bottom: solid 1px #ececea;
}

.offset-car-vehicle-detail-head p {
  font-weight: 600;
  font-size: 15px;
}

.offset-car-vehicle-detail-btn button {
  background-color: #88ae47 !important;
  color: #ffffff !important;
  border-radius: 8px !important;
  height: 48px !important;
  border: none !important;
  font-weight: 500;
  width: 100%;
}

/* ---------------Offset Flight Tab css ----------------------- */
.carbon-offset-flight-sec {
  padding: 0px 20px 10px 20px;
  margin-top: -35px;
}

.carbon-offset-flight-sec .ant-card {
  border-radius: 14px;
}

.carbon-offset-flight-sec .ant-card-bordered {
  border: 2px solid #88ae47;
  background: #fafaf8;
}

.offset-flight-radio
  .ant-radio-wrapper-checked
  .ant-radio-checked
  .ant-radio-inner {
  border-color: #63959b;
}

.offset-flight-radio .ant-radio-wrapper {
  margin-right: 30px;
}

.offset-flight-radio
  .ant-radio-wrapper-checked
  .ant-radio-checked
  .ant-radio-inner::after {
  background-color: #63959b;
}

.offset-flight-radio .ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
  border-color: #63959b !important;
}

.flight-card-content {
  padding-top: 20px;
}

.flight-card-content
  .ant-select-single.ant-select-sm:not(.ant-select-customize-input)
  .ant-select-selector {
  padding: 13px 18px;
  height: 50px;
}

.flight-card-content .ant-row .ant-col p {
  font-size: 14px;
  color: #7d7d7d;
}

.flight-card-content
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border-radius: 10px;
}

.FlightRightLeft {
  width: 30px;
  height: 30px;
}

.pad-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.flight-card-content .flight-pass-data {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.flight-card-content .flight-pass-data .flight-img-card {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 6px;
  padding: 5px;
  margin: 0px 30px;
  cursor: pointer;
}

.offset-order-summery-label .flight-pass-data p {
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 95px;
}

.flight-card-content .flight-pass-data .flight-img-card img {
  width: 20px;
}

.flight-card-content .d-flex-center {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.carbon-offset-btn {
  background-color: #88ae47 !important;
  padding: 0px 56px !important;
  border-radius: 6px !important;
  color: #fff !important;
  height: 55px !important;
  float: right;
}

.flight-card-content .ant-btn:hover,
.ant-btn:focus {
  border-color: transparent !important;
}

/* ------------------------------------------------- */
.greentxt {
  color: #88ae47 !important;
}

.offset-mtp {
  margin-top: -15px !important;
}

.offset-order-summery-label {
  padding-top: 10px;
}

.offset-order-summery-label .ant-row .ant-col p {
  font-size: 14px;
  color: #7d7d7d;
}

.offset-order-summery-label .flight-pass-data {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.offset-order-summery-label .flight-pass-data .flight-img-card {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 6px;
  padding: 5px;
  cursor: pointer;
}

.offset-order-summery-label .mr-lft {
  margin-left: 30px;
}

.offset-order-summery-label .mr-rght {
  margin-right: 30px;
}

.offset-order-summery-label .flight-pass-data .flight-img-card img {
  width: 20px;
}

.offset-order-summery-label .d-flex-center {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.offset-onetime-household-sec-head p {
  font-weight: 600;
  font-size: 15px;
}

.offset-onetime-household-sec-text p {
  color: #9f9f9f;
}

.offset-house-btn-sec {
  border-top: solid 1px #ececea;
}

.other-transport-add-stage-btn button {
  background: none !important;
  color: #88ae47 !important;
  border: none;
  box-shadow: none;
  padding: 0 !important;
}

.other-transport-add-stage-btn button :hover {
  background: none;
  color: #88ae47;
}

.btn-img-span {
  width: 25px;
  padding-bottom: 2px;
}
.other-transport-remove-btn {
  padding-top: 8px;
  cursor: pointer;
}
.other-transport-remove-btn img {
  border: solid 1px #ececea;
  width: 25px;
  padding: 2px;
  border-radius: 50%;
}

.padding-t-b {
  padding: 20px 0;
}

.offset-flight-card {
  padding: 20px 0;
}

.offset-flight-card .ant-row .ant-col label {
  font-size: 18px;
  font-weight: 800;
}

.offset-flight-card .ant-row .ant-col span {
  font-size: 14px;
  color: #acacac;
  font-weight: 500;
  display: block;
  /* word-break: break-word; */
}

.offset-flight-card .flight-place-way img {
  background: #fff;
  padding: 8px;
  border-radius: 24px;
  width: 36px;
  font-size: 13px;
  text-align: center;
  box-shadow: 1px 2px 8px 1px rgb(0 0 0 / 32%);
  position: relative;
  top: -34px;
  left: 90px;
}
.off-ord-flight-data-card .ant-row .ant-col span {
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 15px 0;
}
.plant-tree-gift-card .ant-row .ant-col span {
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 0 !important;
}

.txt-algn-cntr {
  align-items: center;
}

.off-ord-flight-data-card .ant-row .ant-col .text-end {
  text-align: end;
}

.off-ord-flight-data-card .ant-row .text-end label {
  font-size: 15px;
  padding: 15px 0;
}

.offset-flight-card hr {
  margin-top: 25px;
}

.offset-flight-card .car-place-line {
  border-top: 1px dashed #acacac;
}

.car-place-line {
  border-top: 1px dashed #acacac;
}

.dsp-grid {
  display: grid !important;
}

.pb-15 {
  padding-bottom: 15px;
}
.select-validation {
  color: red;
}
.plant-tree-ord {
  /* padding-left: 20px; */
}
.plant-tree-ord ul li {
  list-style: none;
}
.txt-no-input {
  font-size: 16px;
  font-weight: 600;
  color: #78777c;
}
.Life_style_NoData {
  display: flex;
  justify-content: center;
}
.ant-popover-inner-content {
  width: 430px;
}
.have_gift-code {
  margin-bottom: 5px;
}
.gift-code-input {
  padding-top: 20px;
}
.gift_card_total {
  padding: 0 20px;
}
.second_cart_projects {
  background-color: #fff !important;
  margin-top: 20px !important;
}
.second_cart_projects .card_project_content {
  padding: 20px 25px 0px 20px;
}
.card_project_content img {
  width: 115px;
}
.card_project_content p {
  color: #acacac;
  padding-top: 15px;
  font-size: 16px;
}
.gift_apply_remove_btn {
  border-radius: 10px !important;
  height: 35px !important;
}
.text_capital {
  text-transform: capitalize;
}
.offset-top-bg img {
  width: 100%;
}
.validate_error {
  color: #ff4d4f;
  font-size: 14px;
  font-weight: 400;
}
.offset-cards-row {
  position: relative;
  bottom: 48px;
}

.off-set-car-img-sec-img img {
  width: 100%;
  max-height: 455px;
  min-height: 455px;
}

.off-set-flight-img-sec-img img {
  width: 100%;
  max-height: 385px;
  min-height: 385px;
}

.off-set-car-img-sec .head p {
  font-size: 20px !important;
  font-weight: 600 !important;
}

.off-set-car-img-sec .text p {
  font-size: 14px !important;
  color: #121212 !important;
  font-weight: 400 !important;
}

.off-set-car-img-sec .text2 p {
  font-size: 14px !important;
  color: #121212 !important;
  font-weight: 400 !important;
}
.font-text16 {
  font-size: 16px;
}
/* --------------Mobile Responsive Start css by Shlok */
@media screen and (min-width: 370px) and (max-width: 670px) {
  .one-time-offset-sec .ant-row {
    flex-flow: row wrap;
    /* display: block; */
  }
  .one-time-offset-sec .ant-col {
    width: 100%;
    margin: 5px 0px;
  }
  .one-time-offset-sec .ant-col-lg-21 {
    max-width: 100% !important;
  }
  .ant-card-head {
    margin-top: 20px;
  }
  .indv-offset-order-btn-buy {
    margin-bottom: 15px;
  }
  .carbon-offset-flight-sec {
    padding: 0;
  }
  .carbon-offset-onetime-life-sec {
    padding: 0;
  }
  .offset_down_heade_back {
    margin-top: 15px;
  }
  .offset-car-vehicle-detail-btn button span{
    display: flex;
    justify-content: center;
  }
  .business-detail-head{
    font-size: 20px;
    line-height: 30px;
  }
}

@media (min-width: 768px) and (max-width: 1366px) {
  .off-set-car-img-sec .head p {
    font-size: 18px !important;
  }

  .off-set-car-img-sec .text p {
    font-size: 13px !important;
  }

  .off-set-car-img-sec .text2 p {
    font-size: 13px !important;
  }
  .indv-offset-order-btn-buy {
    margin-bottom: 15px;
  }
  .carbon-offset-flight-sec {
    padding: 0;
  }
  .carbon-offset-onetime-life-sec {
    padding: 0;
  }
  .offset_down_heade_back {
    margin-top: 15px;
  }
}

@media only screen and (max-width: 1399px) {
  .offset-flight-card .flight-place-way img {
    left: 55px;
  }
  .indv-offset-order-btn-buy {
    margin-bottom: 15px;
  }
  .carbon-offset-flight-sec {
    padding: 0;
  }
  .carbon-offset-onetime-life-sec {
    padding: 0;
  }
  .offset_down_heade_back {
    margin-top: 15px;
  }
}

.carbon-app-Banner {
  background-color: #f4f4f4 !important;
  background: url(/static/media/carbon_bgimg.940adbaa.png) no-repeat right 40px top;
}

.color-black {
  color: #000000 !important;
  margin: 8px 0 0 0 !important;
}

.font-wg-500 {
  font-weight: 500 !important;
  padding-bottom: 20px;
}

.carbon-app-bann-row {
  padding: 0px;
}

.carbon-app-bann-row h1 {
  font-weight: 400;
}

.carbon-app-bann-row h1 span {
  font-weight: 700;
  margin: 12px 0 !important;
}

.carbon-app-bann-row p {
  color: #969696;
  margin: 26px 0;
  margin: 26px 0 0 0;
}

.carbon-app-bann-btn-sec {
  padding: 50px 0;
}

.carbon-app-bann-green-btn a {
  color: #000000 !important;
}

.carbon-app-bann-green-btn button {
  background-color: #88ae47 !important;
  border: none;
  padding: 22px 26px;
  border-radius: 16px;
  width: 205px;
  font-weight: 600;
}

.carbon-app-bann-blue-btn button {
  background-color: #c2e7ed;
  color: #000000;
  border: none;
  padding: 22px 26px;
  border-radius: 16px;
  width: 205px;
  font-weight: 600;
}

.carbon-app-reduce-emissions {
  padding: 56px 0;
}

.carbon-app-reduce-emissions-head {
  text-align: center;
}
.partners-head {
  font-weight: 700;
}

.carbon-app-reduce-emissions h3 span {
  font-weight: 700;
  padding-left: 10px;
  padding-right: 10px;
}

.carbon-app-reduce-emissions-tabs .ant-tabs-nav-wrap {
  justify-content: center;
}
.carbon-app-reduce-emissions-tabs .ant-tabs-nav {
  padding-bottom: 30px;
}

.carbon-app-reduce-emissions-tabs .ant-tabs-top > .ant-tabs-nav::before {
  border-bottom: none !important;
}

.carbon-app-reduce-emissions-tabs .ant-tabs-nav-list {
  background-color: #f3f3f3 !important;
  padding: 0px 12px;
  border-radius: 8px;
}

.carbon-app-reduce-emissions-tabs .ant-tabs-tab-active {
  background: #fff;
  padding: 0px 20px;
  margin: 6px 0 6px 15px !important;
  border-radius: 6px;
  width: 100px;
}
.carbon-app-reduce-emissions-tabs .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #000000 !important;
  font-weight: 500;
}
.carbon-app-reduce-emissions-tabs .ant-tabs-tab:hover {
  color: #000000;
}
.carbon-app-reduce-emissions-tabs .ant-tabs-tab-active .ant-tabs-tab-btn:focus,
.ant-tabs-tab-remove:focus,
.ant-tabs-tab-btn:active,
.ant-tabs-tab-remove:active {
  color: #000000 !important;
}
.carbon-app-reduce-emissions-tabs .ant-tabs-ink-bar {
  display: none;
}

.carbon-action-sec {
  background: url("https://minio.b120578.dev.eastus.az.svc.builder.cafe/sbucket/variants/5rxwo1ps1jnsdart95lniikzuvzy/6f7daa6c86a9bc2c53f5d0684447f253adfa8f13401d95f9d66464245ed9ff2e?response-content-disposition=inline%3B%20filename%3D%22home_sec_change_climate.jpg%22%3B%20filename%2A%3DUTF-8%27%27home_sec_change_climate.jpg&response-content-type=image%2Fjpeg");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 48px;
  border-radius: 8px;
}

.carbon-action-img-overlay-sec {
  background-color: #ebeaeaf2;
  padding: 32px;
  border-radius: 8px;
}

.carbon-climate-inner-part-sec button {
  background-color: #88ae47;
  color: #fff;
  border-radius: 10px;
  border: none;
  padding: 12px 32px;
}
.carbon-climate-inner-part-sec button a {
  color: #fff;
}
.why-climate-change-sec {
  background: #f5f5f5 !important;
}
.why-climate-change-sec-in {
  text-align: center;
}

.why-climate-change-sec-in img {
  width: 150px;
  height: 150px;
}

.why-climate-change-sec-in .head {
  font-size: 18px;
  font-weight: 700;
}

.why-climate-change-sec-in .text {
  font-weight: 500;
  font-size: 15px;
  text-align: center;
  color: #a0a0a0;
}

.why-climate-change-sec-head p {
  font-size: 24px;
  text-align: center;
  font-weight: 400;
}
.why_climate_center {
  justify-content: center;
}
.why-climate-change-sec-head p span {
  font-weight: 700;
}

.carbon-app-sec-faq {
  background-color: #f4f4f4;
}

.carbon-app-sec-faq .head {
  font-weight: 600;
  text-align: center;
  font-size: 24px;
}

.carbon-app-sec-faq .ant-collapse {
  border: none;
  background: none;
}

.carbon-app-sec-faq .ant-collapse > .ant-collapse-item {
  background: #ffffff !important;
  border: none;
  margin-bottom: 10px;
  border-radius: 10px;
}

.carbon-home-started-sec {
  background: url(/static/media/started_bgImg.9c7a9657.png) no-repeat -30px;
  background-size: 100%;
}
.carbon-home-started-sec .upp {
  padding: 0px 125px;
}

.carbon-home-started-sec .head {
  font-weight: 800;
  text-align: center;
  font-size: 24px;
}

.carbon-home-started-sec .btn .carbon-app-get-btn-sec {
  justify-content: center;
}

.carbon-home-started-sec .img img {
  width: 100%;
}
.text p {
  font-weight: 500;
  color: #8c8c8c;
}

.carbon-banner-img img {
  width: 100%;
}
.carbon_emission {
  padding: 20px 0px 0px 0px;
}

.carbon_emission .label {
  font-size: 20px;
  font-weight: 600;
}
.carbon_emission .description {
  color: #a0a0a0;
  font-size: 16px;
  font-weight: 400;
}

.hr_col {
  border-left: 1px solid #f3f3f3;
}
.carbon_emission_img {
  background-color: #fff;
  border-radius: 15px;
  max-height: 145px;
  padding: 25px;
  display: flex;
  align-items: center;
  box-shadow: 0px 0px 40px #dfe5bf;
  width: 145px;
}

.padding-b50 {
  padding-bottom: 50px;
}
.justify_space_evenly {
  justify-content: space-evenly !important;
}
.carbon-project-categories {
  padding: 56px 0;
  background-color: #f7f7f7;
}
.carbon-project-categories-head {
  text-align: center;
  margin-bottom: 45px;
}

.carbon-project-categories h3 {
  font-weight: 800;
}
.carbon-project-categories h3 span {
  font-weight: 400;
}
.carbon_pro_cat_img {
  border-radius: 15px;
}
.pro_cat_img_center {
  display: flex;
  justify-content: center;
}
.pro-cat-img {
  border-radius: 22px;
  padding: 7px 14px;
  font-size: 14px;
  font-weight: 500;
  margin: 5px 0px 0px 12px;
  top: 15px;
  position: absolute;
  /* right: 300px; */
  background: rgba(0, 0, 0, 0.4);
  color: #f1f1f1;
}
.read_artical_center {
  display: flex;
  justify-content: center;
  margin-bottom: 60px;
  position: relative;
}
.read_artical .container .read_more {
  display: flex;
  justify-content: center;
  color: #88ae47;
  font-size: 17px;
  font-weight: 600;
  cursor: pointer;
}
.read_artical_center .ArticleImg1 {
  max-width: 700px;
}
.article_profile_img .ArticleImg2 {
  max-width: 400px;
}
.right_move {
  margin-right: 285px !important;
}
.read_artical_center .article_content {
  background-color: #f4f4f4;
  margin: 170px 0px 75px;
  padding: 40px 40px;
  border-radius: 12px 12px 0 12px;
  margin-left: -100px;
}
.article_profile_img .article_content {
  width: 100%;
}
.read_artical_center .article_content h4 {
  font-size: 32px;
  font-weight: 700;
}
.article_profile_img .article_content h4 {
  font-size: 16px;
  font-weight: 700;
}
.read_artical_center .left_view .artical_profile {
  width: 35px;
}
.article_info .left_view img {
  width: 40px;
}
.article_info .left_view span {
  padding-left: 8px;
  font-size: 12px;
  font-weight: 500;
  color: #8c8c8c;
}
.article_info .right_view p {
  padding-top: 10px;
  font-size: 12px;
  font-weight: 500;
  color: #8c8c8c;
}
.article_info {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}
.read_artical_center .article_btn {
  position: absolute;
  right: 0;
  bottom: 0;
  width: calc(100% - 700px);
  height: 75px;
}
.article_profile_img .article_btn {
  width: calc(100% - 400px);
}
.read_artical_center .article_btn .ant-btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 12px;
  cursor: pointer;
  border-color: #88ae47 !important;
  background: #88ae47 !important;
  display: block;
  width: 100%;
  height: 100%;
}

.carbon-pro-cat-card {
  border-bottom-right-radius: 14px;
  border-bottom-left-radius: 14px;
  background-color: #fff;
  height: 60px;
  padding: 18px 30px;
  top: -25px;
  position: inherit;
}
.carbon-pro-cat-card h4 {
  font-size: 18px;
  font-weight: 700;
}
.ant-col-lg-21 {
  max-width: 72.5% !important;
}

@media screen and (max-width: 998px) {
  .carbon-app-bann-green-btn button,
  .carbon-app-bann-blue-btn button {
    width: 100%; /*165px; */
  }
}

@media screen and (max-width: 767px) {
  .padding-b50 {
    padding-bottom: 30px;
  }
  .carbon-app-bann-btn-sec {
    padding: 18px 0;
  }

  .carbon-app-bann-blue-btn,
  .carbon-app-bann-green-btn {
    display: flex;
    justify-content: center;
  }
}
@media screen and (max-width: 769px) {
  .padding-b50 {
    padding-bottom: 30px;
  }
  .home_section {
    justify-content: center;
  }
  .mt-5 {
    margin-top: 0 !important;
  }
  .home_section .ant-col {
    margin-bottom: 15px;
  }
  .carbon-app-reduce-emissions-head h3 {
    font-size: 15px;
  }

  .carbon-home-started-sec .upp {
    padding: 0;
  }
  .carbon-home-started-sec .container .w-100 .ant-row .ant-col {
    margin-bottom: 15px;
  }
  .pt-5 {
    padding-top: 2rem !important;
  }
  .read_artical {
    display: block;
    width: 100%;
  }
  .read_artical_center {
    display: block;
    margin-bottom: -210px;
  }
  .read_artical_center .article_content {
    position: relative;
    bottom: 250px;
    margin-left: 0;
  }
  .read_artical_center .article_btn {
    width: 100%;
    bottom: 180px;
  }
  .read_artical_center .article_btn .ant-btn {
    border-radius: 0 0 14px 14px;
  }
  .read_artical_center .article_content h4 {
    font-size: 18px;
  }
  .article_info .left_view span {
    font-size: 11px;
  }
  .article_profile_img .ArticleImg2 {
    max-width: 100%;
  }
  .read_artical_center .ArticleImg1 {
    height: 470px;
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .read_artical {
    display: block;
    width: 100%;
  }
  .why-climate-change-sec-head p {
    font-size: 18px;
    margin-top: 10px;
  }
  .read_artical_center {
    display: block;
    margin-bottom: -210px;
  }
  .py-5 {
    padding-top: 0 !important;
  }
  .read_artical_center .article_content {
    position: relative;
    bottom: 250px;
    margin-left: 0;
    padding: 25px 40px;
  }
  .read_artical_center .article_btn {
    width: 100%;
    bottom: 180px;
  }
  .read_artical_center .article_btn .ant-btn {
    border-radius: 0 0 14px 14px;
  }
  .read_artical_center .article_content h4 {
    font-size: 18px;
  }
  .article_info .left_view span {
    font-size: 11px;
  }
  .article_profile_img .ArticleImg2 {
    max-width: 100%;
  }
  .read_artical_center .ArticleImg1 {
    height: 470px;
    width: 100%;
  }
}

.indv-dash-custom-tbl .ant-table-thead > tr > th {
  text-align: center;
  background: none;
  color: #c3c3c3;
  font-weight: 500;
}
.name_change {
  text-align: left !important;
}
.Image_change {
  width: 40px;
  padding: 16px 0px 16px 16px !important;
  text-align: left !important;
}
.same_change {
  width: 212px;
}
.indv-dash-custom-tbl
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  display: none !important;
}

.indv-dash-custom-tbl .ant-table-tbody > tr > td {
  text-align: center;
  background-color: #fafafa !important;
}

.term-condition-phead-txt {
  font-weight: 600;
  font-size: 15px;
}

.term-condition-para-txt {
  font-size: 14px;
  color: #83889e;
}

.term-para-link {
  color: #0000ee !important;
}

.why-climate-change-sec-text p {
  font-weight: 500;
  font-size: 16px;
  text-align: center;
}

.why-climate-change-sec-text p span {
  color: #a0a0a0;
}

.climate-action-btnsec p {
  font-weight: 400;
  font-size: 12px;
  color: #a0a0a0;
}

.climate-action-btnsec-btn2 {
  background: none;
  width: 215px;
  height: 56px;
  font-weight: 600;
  font-size: 16px;
  border: #86af48 solid 2px;
  border-radius: 3px;
}

.climate-action-btnsec-btn2 a {
  color: #202020 !important;
}

.climate-action-btnsec-btn1 {
  width: 215px;
  height: 48px;
  border: none;
  background: #86af48 !important;
  border-radius: 3px;
  color: #ffffff !important;
  font-weight: 500;
  font-size: 16px;
}

.climate-action-btnsec-btn1 a {
  color: #ffffff !important;
  font-weight: 500;
}

.climate-action-not-difficult {
  padding: 50px 0;
}

.climate-action-not-difficult-head p {
  text-align: center;
  font-size: 30px;
  line-height: 48px;
  font-weight: 700;
}

.climate-action-not-difficult-head p span {
  font-weight: 500;
  font-size: 24px;
}

.climate-action-not-difficult-para-number {
  font-weight: 500;
  font-size: 30px;
  color: #000000;
  margin-bottom: 0;
}

.climate-action-not-difficult-para-head {
  font-weight: 500;
  font-size: 20px;
  color: #000000;
  margin-bottom: 0;
}

.climate-action-not-difficult-para-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #a0a0a0;
}

.transparency-at-our-code-sec {
  padding: 50px 0;
  background: #fff;
}

.transparency-at-our-code-sec-head p {
  text-transform: uppercase;
  text-align: center;
  font-size: 28px;
  line-height: 45px;
  margin-bottom: 15px !important;
}

.transparency-at-our-code-sec-head p span {
  font-weight: 700;
}

.transparency-at-our-code-sec-text p {
  text-align: center;
}

.transparency-at-our-code-sec-block,
.transparency-at-our-code-sec-block4 {
  background: #ffffff;
  padding: 50px 60px;
  height: 200px;
  width: 100%;
  position: relative;
}

.transparency-at-our-code-sec-block2,
.transparency-at-our-code-sec-block4 {
  margin-top: 1px;
}

.transparency-at-our-code-sec-block2,
.transparency-at-our-code-sec-block3 {
  background: #ffffff;
  padding: 50px 60px;
  height: 200px;
  width: 100%;
  position: relative;
}

.transparency-at-our-code-sec-block p,
.transparency-at-our-code-sec-block2 p,
.transparency-at-our-code-sec-block3 p,
.transparency-at-our-code-sec-block4 p {
  font-weight: 500;
  font-size: 20px;
  color: #202020;
  margin-bottom: 0;
}

.climate-offsetting-projects-sec {
  margin-top: 80px;
}

.climate-offsetting-projects-head p {
  text-align: center;
  font-size: 32px;
  line-height: 48px;
}

.climate-offsetting-projects-head p span {
  font-weight: 700;
}

.climate-offsetting-projects-text p {
  text-align: center;
  font-size: 16px;
  color: #000000;
}
.climate-offseting-sec-leftblock-head {
  font-weight: 600;
  font-size: 30px;
  color: #000000;
  margin-bottom: 14px;
}

.climate-offseting-sec-leftblock-text {
  font-weight: 400;
  font-size: 18px;
  color: #000000;
  margin-top: 12px;
  text-align: justify;
}

.climate-offseting-sec-rightblock-head {
  font-weight: 600;
  font-size: 30px;
  color: #000000;
  margin-bottom: 1px;
}
.climate-offseting-sec-rightblock-text {
  font-weight: 400;
  font-size: 18px;
  color: #000000;
  margin-top: 12px;
  text-align: justify;
}
.our-approach-carbon-offsetting-works {
  background: #f5f5f5;
  padding: 80px 0;
}

.our-approach-carbon-offsetting-works .head p {
  font-size: 32px;
  line-height: 48px;
}

.our-approach-carbon-offsetting-works .head p span {
  font-weight: 700;
}

.our-approach-carbon-offsetting-works .para {
  font-weight: 500;
  font-size: 15px;
  color: #000000;
}

.our-approach-carbon-offsetting-works .para2 {
  font-weight: 500;
  font-size: 14px;
  color: #a0a0a0;
  margin-top: 5px;
}

.carbon-offsetting-work-block {
  padding: 32px;
  background-color: #ffffff;
}

.carbon-offsetting-work-block-head {
  font-weight: 600;
  font-size: 18px;
  line-height: 36px;
  color: #202020;
}

.carbon-offsetting-work-block-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  color: #a0a0a0;
}

.carbon-offsetting-work-block-text ul {
  padding-left: 0px;
}

.carbon-offsetting-work-block-text ul li {
  list-style: none;
}

.margin-top32 {
  margin-top: 32px;
}

.carbon-offseting-work-vector3 img {
  position: absolute;
  left: -22px;
}

.carbon-offsetting-work-down-vector img {
  position: absolute;
  top: -15px;
}

.carbon-offsetting-work-down-para p {
  font-weight: 500;
  font-size: 24px;
  line-height: 40px;
  color: #000000;
}

.text-uppercase {
  text-transform: uppercase;
}

.tackle-climate-change-part {
  background-color: #ffffff;
  margin: 180px 0px;
  padding: 32px;
  border-radius: 8px;
  width: 50rem;
  /* min-height: 292px; */
}
.tackle-climate-change-part .details_para {
  font-weight: 500;
  font-size: 15px;
  line-height: 25px;
}

.our-approach-main-sec {
  background: url("https://minio.b120578.dev.eastus.az.svc.builder.cafe/sbucket/variants/aj0741yrb5rko20ylrwp2aa1dja2/6f7daa6c86a9bc2c53f5d0684447f253adfa8f13401d95f9d66464245ed9ff2e?response-content-disposition=inline%3B%20filename%3D%22approach-banner.jpg%22%3B%20filename%2A%3DUTF-8%27%27approach-banner.jpg&response-content-type=image%2Fjpeg");
  background-size: cover;
  background-repeat: no-repeat;
  padding: 192px 0px 156px 0;
}

.our-approach-main-sec-head h1 {
  text-align: center;
  font-weight: 500;
  font-size: 48px;
  line-height: 64px;
}

.our-approach-main-sec-head h1 span {
  font-weight: 700;
  padding-left: 8px;
  font-size: 26px;
}

.our-approach-main-sec-text p {
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  color: #404040;
}

.climate-project-img {
  padding: 25px 0px 25px 0px;
}

.climate-project-img img {
  width: 100%;
  border-radius: 8px;
}

.climate-project-img-left {
  padding: 25px 18px 25px 0px;
}

.climate-project-img-left img {
  width: 100%;
  border-radius: 8px;
}

.climate-project-img-left2 {
  padding: 25px 0px 25px 0px;
}

.climate-project-img-left2 img {
  width: 100%;
  height: 385px !important;
  border-radius: 8px 0px 0px 8px;
}

.climate-project-img-left3 {
  padding: 25px 0px 25px 0px;
}

.climate-project-img-left3 img {
  width: 100%;
  border-radius: 0px 8px 8px 0px;
  height: 385px !important;
}

.carbon-setting-imgs {
  width: 100px;
  height: 100px;
}

.for-indv-text-home-btn button {
  color: #ffffff;
  font-weight: 500;
  font-size: 16px;
  height: 68px;
}

.for-buss-text-home-btn button {
  color: #202020;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  height: 68px;
}

.carbon_approach_emission {
  padding: 10px 0px 0px 0px;
}

.carbon_approach_emission .label {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 8px;
}
.carbon_approach_emission .description {
  color: #a0a0a0;
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 10px;
}

.carbon-action-sec {
  margin-bottom: 106px;
}

.carbon-action-sec-head {
  font-size: 24px !important;
  text-align: center;
  margin: 48px 0;
}

.carbon-action-sec-head span {
  font-weight: 700;
  font-size: 28px;
}
.color-green {
  color: #88ae47 !important;
}
.color-green-no {
  color: #88ae47 !important;
  text-decoration: underline;
}
.climate_no {
  margin-top: 12px;
}
.climate_no p {
  font-size: 16px;
  cursor: pointer;
}

.carbon-action-sec-text1 p {
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 15px;
}

.carbon-action-sec-text2 p {
  font-weight: 500;
  font-size: 18px;
  /* margin-top: 48px; */
}

.carbon-action-sec-text3 p {
  font-weight: 400;
  font-size: 15px;
}

.carbon-action-sec-text4 p {
  font-weight: 500;
  font-size: 18px;
  margin-top: 45px;
}

.dashboard_img_sec img {
  padding-bottom: 20px;
}
.class_hove1 {
  cursor: pointer;
  background: black;
}
.class_hove1_image {
  display: none !important;
}
.class_hove1 :hover {
  background: white;
}
.class_hove1:hover .class_hove1_image {
  display: block !important;
}
.our-approach-main-sec-head .climate_change {
  font-weight: 700;
  padding-left: 8px;
  font-size: 48px;
}
@media only screen and (max-width: 1399px) {
  .carbon_emission .label {
    font-size: 19px;
  }

  .carbon_emission .description {
    font-size: 15px;
  }
}
@media screen and (min-width: 370px) and (max-width: 670px) {
  .carbon-app-bann-row h1 {
    text-align: center;
    padding-top: 10px;
  }
}

@media (min-width: 768px) and (max-width: 1366px) {
  .transparency-at-our-code-sec-block,
  .transparency-at-our-code-sec-block4 {
    height: 200px !important;
  }
  .transparency-at-our-code-sec-block2,
  .transparency-at-our-code-sec-block3 {
    height: 200px !important;
  }
  .carbon-action-sec-text1 p {
    margin-bottom: 46px;
  }
}

@media screen and (max-width: 768px) {
  .our-approach-main-sec-head .climate_change {
    font-weight: 700;
    padding-left: 8px;
    font-size: 24px;
  }
  .our-approach-main-sec-head h1 {
    text-align: center;
    font-weight: 500;
    font-size: 24px;
    line-height: 48px;
  }
  .sideArrow,
  .sideArrowDown {
    display: none;
  }
  .tackle-climate-change-part {
    width: auto;
  }
  .border-css-custom {
    border: none !important;
  }
  .climate-action-image {
    margin-right: 20px;
  }
  .transparency-at-our-code-sec-head p {
    text-transform: uppercase;
    text-align: center;
    font-size: 22px;
    line-height: 40px;
    margin-bottom: 15px !important;
  }
  .climate-offsetting-projects-head p {
    text-align: center;
    font-size: 26px;
    line-height: 40px;
  }
  .climate-offsetting-projects-sec {
    margin-top: 10px;
  }
  .climate-offseting-sec-leftblock-head {
    font-weight: 600;
    font-size: 26px;
    color: #000000;
    margin-bottom: 14px;
  }
  .climate-project-img-left {
    padding: 25px 0px 25px 0px;
  }
  .climate-offseting-sec-rightblock-head {
    font-weight: 600;
    font-size: 24px;
    color: #000000;
    margin-bottom: 1px;
  }
  .climate-offseting-sec-leftblock-head {
    font-weight: 600;
    font-size: 24px;
    color: #000000;
    margin-bottom: 14px;
  }
  .climate-action-not-difficult-head p {
    text-align: center;
    font-size: 24px;
    line-height: 40px;
    font-weight: 700;
  }
  .our-approach-carbon-offsetting-works .head p {
    font-size: 24px;
    line-height: 36px;
  }
  .our-approach-main-sec {
    padding: 115px 0px 90px 0;
  }
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector {
  padding: 6px 11px;
  height: 50px;
}
/*================  Leftcontainer =============== */

.Individual_leftScreen {
  display: flex;
  flex-direction: column;
  width: 200px;
  /* height: 573px; */
  height: 908px;
  border-radius: 10px;
  overflow: scroll;
  line-height: 40px;
}
.leftScreen {
  width: 200px;
  height: 100vh;
  border-radius: 10px;
  background-color: var(--color-light-gray) !important;
}
.climate_project_head {
  padding: 30px 0 20px 20px !important;
}
.climate_project_head h2 {
  font-size: 30px;
}
.climate_project_home {
  flex-flow: nowrap !important;
}
.menuItem {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 0px 16px;
  padding: 0px 16px;
  line-height: 3.5;
}
.menuItem:hover {
  background-color: var(--color-light-gray-hover);
  border-radius: 5px;
}
.MenuImg {
  width: 34px;
  height: 24px;
  padding-right: 10px;
}
.menulist {
  padding: 0;
  margin-top: 10px;
}
.set_arrow {
  position: relative;
}
.sidearrow {
  width: 10px;
  position: absolute;
  left: 0;
  top: 14px;
  /* visibility: hidden; */
}
.hamburger_toggle {
  cursor: pointer;
  display: none;
}
.menulist:active .sidearrow {
  visibility: visible;
}

@media screen and (max-width: 820px) {
  .leftScreen {
    width: 100%;
  }
  .menuItem {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin: 20px 16px;
    padding: 0px 16px;
    justify-content: center;
    line-height: 10;
  }
  .menulist {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
  .menuItem:hover {
    background-color: var(--color-light-gray-hover);
    border-radius: 5px;
  }
  .hamburger_toggle {
    /* width: 100px;
    height: 100px; */
    display: block;
    position: fixed;
    top: 8px;
    right: 0;
    right: 16px;
    font-size: 30px;
    z-index: 9999;
  }

  .leftScreen {
    background-color: var(--color-light-gray) !important;
    display: flex;
    position: fixed;
    justify-content: center;
    align-items: center;
    top: 60px;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
    transition: transform 0.2s ease-in-out;
    transform: translateX(100%);
  }

  .leftScreenSlideIn {
    border-radius: 10px;
    line-height: 38px;
    background-color: var(--color-light-gray) !important;
    display: flex;
    position: fixed;
    justify-content: center;
    align-items: center;
    top: 60px;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
    transition: transform 0.2s ease-in-out;
    transform: translateX(0%);
    z-index: 99;
  }
}

.menuItem {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 0px 16px;
  padding: 0px 16px;
}

.menuItem:hover {
  background-color: var(--color-light-gray-hover);
  border-radius: 5px;
}
ul {
  padding: 0;
}
.medileScreen {
  display: flex;
}

@media screen and (max-width: 820px) {
  .Individual_leftScreen {
    width: 50%;
  }
}
@media screen and (max-width: 480px) {
  .leftScreen {
    width: 100%;
  }
  .menuItem {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin: 40px 16px;
    padding: 0px 16px;
    justify-content: center;
    line-height: 2;
  }
  .menulist {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .menuItem:hover {
    background-color: var(--color-light-gray-hover);
    border-radius: 5px;
  }
  .hamburger_toggle {
    /* width: 100px;
    height: 100px; */
    display: block;
    position: fixed;
    top: 4px;
    right: 0;
    right: 16px;
    font-size: 30px;
    z-index: 9999;
  }

  .leftScreen {
    background-color: var(--color-light-gray) !important;
    display: flex;
    position: fixed;
    justify-content: center;
    align-items: center;
    top: 60px;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
    transition: transform 0.2s ease-in-out;
    transform: translateX(100%);
  }

  .leftScreenSlideIn {
    border-radius: 10px;
    line-height: 38px;
    background-color: var(--color-light-gray) !important;
    display: flex;
    position: fixed;
    justify-content: center;
    align-items: center;
    top: 60px;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
    transition: transform 0.2s ease-in-out;
    transform: translateX(0%);
    z-index: 99;
  }

  .T_M_Screen_btn {
    justify-content: center !important;
    flex-wrap: wrap;
  }
}
/* *******************Individual_Main_Middle_Container*************** */
.Individual_Main_Middle_Container {
  display: flex;
  width: 100%;
  margin-top: 68px;
}

@media screen and (max-width: 420px) {
  .Individual_leftScreen {
    width: 100%;
  }
}

/*================== middleContainer ================*/

/* .Individual_MiddleContainer {
   display: flex;; 
  margin-top: 80px;
} */

.Individual_MiddleScreenMain {
  display: flex;
  flex-direction: column;
  padding: 24px;
}

.Individual_middleScreen1 {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
}

.Individual_first_block {
  /* width: 50%; */
  height: 212px;
  background-color: var(--color-white);
  /* margin: 9px 10px; */
  border-radius: 22px;
  box-shadow: 0px 0px 16px -3px rgb(0 0 0 / 51%);
}
.business_first_block {
  height: 200px;
  background-color: var(--color-white);
  border-radius: 22px;
  box-shadow: 0px 0px 16px -3px rgb(0 0 0 / 51%);
}

.first_block1 {
  display: flex;
  width: 100%;
  height: 140px;
  background-color: var(--color-individual);
  border-radius: 22px;
}
.business_first_block .first_block1 {
  display: flex;
  width: 100%;
  height: 135px;
  background-color: var(--color-individual);
  border-radius: 22px;
}

.first_block_img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 140px;
}

.first_blockT {
  color: var(--color-white);

  font-size: 19px;
  font-weight: 600;
}

.firstblockN {
  color: var(--color-white);
  font-size: 22px;
  margin-right: 8px;
}

.blockImg {
  width: 32px;
  height: 32px;
  margin: 4px 8px 0px 0px;
}

.first_block_right {
  display: flex;
  width: 70%;
  height: 140px;
  justify-content: flex-end;
}

.first_b_inner {
  display: flex;
  width: 50%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-right: 22px;
}
.first_b_inner span {
  color: #f0fde2;
  font-size: 16px;
  font-weight: 600;
}
.first_block_rightP {
  color: var(--color-individual-white);

  background: none;
  border: none;
}

.first_btn {
  background: #f4f8ea;
  padding: 10px 20px;
  border-radius: 10px;
  border-color: var(--color-individual-white);
  color: var(--color-individual);
  border: 0;
  font-size: 19px;
}

.first_blocklastP_main {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  font-size: 17px;
}

.Individual_second_block {
  justify-content: space-between;
}

.Individual_first_square {
  /* width: 50%; */
  height: 212px;
  background-color: var(--color-individual-firstsquare);
  border-radius: 22px;
}
.business_first_square {
  height: 200px;
  background-color: var(--color-individual-firstsquare);
  border-radius: 22px;
}

.firstsquare_box {
  padding: 25px 30px;
}

.firstsquareN {
  color: var(--color-black);
  font-size: 26px;

  padding: 5px 0px 0px 0px;
  margin-bottom: 0;
}
.business_first_square .firstsquareN {
  color: var(--color-black);
  font-size: 40px;

  padding: 5px 0px 0px 0px;
  margin-bottom: 0;
}

.firstsquareT {
  color: var(--color-individual);
  font-size: 17px;
  padding-top: 20px;
  padding-left: 8px;
  padding-right: 8px;
}
.firstsquareT1 {
  margin-left: 10px !important;
}
.firstsquareT2 {
  margin-left: 20px !important;
}
.business_first_square .firstsquareT {
  color: var(--color-individual);
  font-size: 17px;

  padding-top: 20px;
}

.Individual_second_square {
  margin-left: 10px;
  background-color: var(--color-individual-secondsquare);
}

.secondsquare_box {
  padding: 25px 30px;
}

.secondsquareN {
  color: var(--color-black);
}

.secondsquareT {
  color: var(--color-individual-secondsquareT);
}

.head-off-plant-text {
  display: flex;
  color: #acacac;
  font-weight: 600;
}
.head-off-plant-text a {
  padding: 0 5px;
  color: #88ae47;
  font-weight: 600;
}
.head-off-plant-text a:hover {
  color: #88ae47;
}
/* .downawar_set {
  width: 100%;
  position: absolute;
  bottom: 0;
} */
.Individual_S_M_Screen {
  /* background: url("../assets/map.png"); */
  width: 100%;
  background-size: cover;
  height: 627px;
  border-radius: 22px;
  margin-top: 22px;
  /* margin: 10px 10px; */
}
.map_screen_main {
  position: relative;
}
.map_screen_main_inner {
  position: absolute;
  top: 0;
}
.map_screen_main .gmnoprint {
  display: none;
}
.map_marker {
  min-width: 265px;
  height: 150px;
  background-color: black;
  position: absolute;
  top: 34%;
  right: 28%;
  border-radius: 15px;
  padding: 12px;
}

.weight-map-symb {
  color: #cfcfcf !important;
  font-weight: 400 !important;
  font-size: 15px !important;
}

.marker_text {
  color: var(--color-white);
  font-size: 18px;

  margin: 0px 5px 0 0;
}
.Marker_see_project {
  color: var(--color-individual);
}
.markerinner {
  display: flex;
  align-items: center;
}
.marker_img {
  width: 55px;
  height: 55px;
  border-radius: 15px;
}
.marker_plant_tree {
  height: 230px;
}
.Marker_project_name {
  color: var(--bs-white);

  margin: 0px 0px 0 10px;
}
.s_m_box {
  align-items: center;
  justify-content: center;
  background-color: var(--color-white);
  border-radius: 19px;
  margin: 23px;
  /* padding: 15px 0; */
}

.s_m_windmil_image {
  width: 46%;
  height: 139px;
}

.s_m_boxP {
  padding: 10px 40px;
  text-align: center;
  font-size: 15px;
  color: var(--color-individual-gray);
}

/******************* S_M_Inner_Box**********************/
.s_m_inner_box1 {
  padding: 12px 10px 0 15px;
}
.hrline {
  margin-top: 0;
  margin-bottom: 0;
}
.s_m_inner_H {
  font-size: 17px;
}

.s_m_inner_btn_main {
  display: flex;
  width: 85%;
  justify-content: space-between;
}

.s_m_inner_btn {
  padding-bottom: 12px;
  background-color: var(--color-white);
  border: none;

  color: #c3c3c3;
}
.climate_project_active_line {
  padding-bottom: 12px;
  background-color: var(--color-white);
  border: none;

  border-bottom: 2px solid var(--color-individual);
}

.s_m_inner_box_hr_line {
  width: 100%;
}

.s_m_inner_box2 {
  margin: 13px;
  /* width: 91%; */
  height: 148px;
  border: 1px solid var(--color-individual-gray);
  border-radius: 10px;
  cursor: pointer;
}
.active_project {
  margin: 13px;
  /* width: 91%; */
  height: 148px;
  border: 2px solid var(--color-individual);
  border-radius: 10px;
  cursor: pointer;
}
.Over_flow_css {
  overflow-x: unset;
  overflow-y: scroll;
  height: 485px;
  width: 320px;
}
.Over_flow_css::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.Over_flow_css::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5;
}

.Over_flow_css::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #e0e0e0;
}
.s_m_box2_inner_part {
  display: flex;
  margin: 10px;
}
.s_m_box2_inner_Image {
  width: 37%;
  height: 66px;
  /* / border: 1px solid var(--color-individual-gray); / */
}
.s_m_inner_Image_main {
  width: 100%;
  height: 100%;
  border-radius: 6px;
}
.s_m_box2_inner_location {
  padding-left: 10px;
}

.s_m_box2_inner_carbon_off {
  display: flex;
  justify-content: center;
  width: 95%;
  height: 50px;
  /* / border: 1px solid var(--color-individual-gray); / */
  margin: 10px;
  align-items: center;
  justify-content: space-between;
  background-color: var(--color-individual-Inner-box);
  border-radius: 6px;
  padding: 8px 2px 8px 10px;
}
.s_m_box2_inner_carbon_off1 {
  margin-right: 10px;
}

/******************* S_M_Inner_Box_End**********************/

.Individual_T_M_Screen {
  width: 100%;
  margin: 10px;
}
.middle_caur {
  width: 355px;
}
.hHJTFD {
  margin: 0 !important;
}
.T_M_Header_Center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* height: 413px; */
  height: 500px;
  /* z-index: 9999; */
}
.Pie_chart_div {
  height: 275px !important;
}
.Pie_chart_div img {
  width: 30% !important;
}
.T_M_ScreenP {
  font-size: 22px;
  font-weight: 500;
  padding: 0px 10px;
  margin: 28px 0 0 3px;
}
.empty_card_lbl_P {
  font-size: 24px;
  font-family: Roboto-Bold;
  padding: 0px 10px;
  margin: 0px 0 0 3px;
}

.T_M_box {
  width: 100%;
  margin: 0px;
  margin-bottom: 15px;
}
/******************** T_m_InnerBox************************/

.T_M_boxMain1 {
  display: flex;
  width: 100%;
}

.T_M_box_inner {
  width: 100%;
  height: 387px;
  background-color: var(--color-white);
  border-radius: 20px;
  /* margin: 10px; */
  position: relative;
  box-shadow: 0px 0px 13px 0px rgb(171 168 168 / 51%);
}

.T_m_wind_inner {
  width: 100%;
  height: 285px;
  border-radius: 20px;
}

.T_M_box_inner1 {
  width: 98%;
  /* height: 56%; */
  position: absolute;
  background-color: var(--color-white);
  bottom: 5px;
  margin: 0 auto;
  border-radius: 20px;
  padding: 10px;
  float: left;
  left: 4px;
}

.climate_pro_box_inner1 {
  width: 98%;
  position: absolute;
  background-color: var(--color-white);
  bottom: 5px;
  margin: 0 auto;
  border-radius: 20px;
  padding: 10px;
  float: left;
  left: 4px;
  cursor: pointer;
}
.climat_pro_Inner_btn {
  display: none;
}
.climate_pro_box_inner1:hover .climat_pro_Inner_btn {
  display: block;
}
.T_M_box_inner2 {
  display: flex;
  flex-direction: column;
}

.T_M_inner2_head {
  font-size: 8px;
  margin: 0px 14px;
}

.T_M_inner2_head1 {
  font-size: 14px;
}

.T_M_inner2_image {
  display: flex;
}

.T_m_location_image {
  width: 17px !important;
  height: 22px;
}

.T_m_innerP {
  font-size: 13px;
  padding: 0px 12px;

  color: var(--color-individual-gray1);
}

.project_decrip {
  font-size: 13px;
  padding: 0px 12px;

  color: var(--color-individual-gray1);
}

.T_M_Inner_box_Main {
  display: flex;
  justify-content: space-between;
}

.T_M_Inner_box {
  width: 47%;
  height: 91px;
  background-color: var(--color-individual-firstsquare);
  margin-top: 8px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .project_slider_back {
  top: 32px !important;
  left: 91% !important;
} */

.back-myproject {
  top: 32px !important;
  left: 91% !important;
}

.project_slider_forward {
  top: 32px !important;
  right: 0% !important;
}

.T_M_Main_box1 {
  display: flex;
  flex-direction: column;
}

.T_M_Inner_boxP {
  color: var(--color-black);
}

.T_M_Inner_boxP1 {
  /* color: #9AB9BC; */
  font-size: 25px;
  font-weight: 600;
}

.T_M_Inner_box1 {
  background-color: var(--color-individual-secondsquare);
}

.T_M_InnerN {
  color: var(--color-individual);
  font-size: 25px;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
}

.T_M_InnerN1 {
  font-size: 24px !important;
  /* color: #97A2A3; */
  color: var(--color-individual-amount1);
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}

.T_M_Main_H {
  font-size: 14px;
  margin-bottom: 0;
}

.T_M_Main_H1 {
  padding-right: 4px;
}

.T_M_Inner_btn {
  width: 100%;
  height: 56px;
  border-radius: 7px;
  margin-top: 10px;
  background-color: var(--color-white);
  font-size: 17px;
  color: #333333;
  font-weight: 500;
}

.climat_pro_Inner_btn {
  width: 100%;
  height: 50px;
  border-radius: 7px;
  margin-top: 10px;
  background: #86af47;
  color: #fff;
  border-color: transparent;
  font-size: 17px;
}

/********************* innerTM_End****************/

.T_M_windmil_image {
  width: 160px;
  height: 140px;
  /* padding: 0px 185px */
}

.T_M_Screen_btn {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.T_M_Screen_btn1 {
  background-color: var(--color-individual-gray);
  padding: 3px;
  align-items: center;
  display: flex;
  border-radius: 10px;
  height: 40px;
  margin-right: 20px;
}

.T_M_Screen_btn_Main {
  border: none;
  font-size: 16px;
  border-radius: 6px;
  background: none;
}

.T_M_Screen_btn_Main_link {
  border: none;
  font-size: 16px;
  border-radius: 6px;
  background-color: var(--bs-white);
}

.Individual_F_M_Screen {
  width: 100%;
  margin: 10px 0px;
}

.F_M_box {
  border-radius: 10px;
  /* height: 165px; */
  background-color: var(--color-white);
  display: flex;
  border: none;
  box-shadow: 0px 0px 16px -3px rgb(0 0 0 / 51%);
  width: 98%;
  margin: 0 10px 0 10px;
}

.F_M_box_Main1 {
  /* width: 55%; */
  height: 150px;
  border-radius: 10px;
  margin: 6px 31px;
  background-size: cover;
  padding: 10px 0;
}

.F_M_box_Img {
  width: 185px;
  height: 135px;
  border-radius: 8px;
}
.F_M_box_Main2 {
  padding-top: 10px;
}

.F_M_box_heading {
  font-size: 22px;
  color: rgba(0, 0, 0, 0.55);
  margin: 12px 0px 0 0;
}

.F_M_box_P {
  color: #969595;
  padding: 0px 36px 10px 0px;
  text-align: justify;
  font-size: 16px;
}

.F_M_box_S {
  cursor: pointer;
  color: var(--color-individual);
}
.Carousel_Main {
  position: relative;
}
.disbleBtnDynamic .rec-arrow-left {
  position: absolute;
  top: 28px;
  right: 52px;
  border: 0 !important;
  box-shadow: none;
  font-size: 14px;
  width: 25px;
  height: 25px;
  min-width: unset;
  border-radius: 5px;
  z-index: 999;
  cursor: pointer;
  background: #ececec;
  color: #706e6e;
  line-height: 25px;
}
.disbleBtnDynamic .rec-arrow-right {
  position: absolute;
  top: 28px;
  right: 20px;
  border: 0 !important;
  box-shadow: none;
  font-size: 14px;
  width: 25px;
  height: 25px;
  min-width: unset;
  border-radius: 5px;
  z-index: 999;
  cursor: pointer;
  background: #ececec;
  color: #706e6e;
  line-height: 25px;
}
.disbleBtnDynamic .rec-arrow-right:hover:enabled,
.disbleBtnDynamic .rec-arrow-right:focus:enabled,
.disbleBtnDynamic .rec-arrow-right:hover {
  border: 0 !important;
  box-shadow: none;
  font-size: 14px;
  width: 25px;
  height: 25px;
  min-width: unset;
  border-radius: 5px;
  z-index: 999;
  cursor: pointer;
  background: #ececec !important;
  color: #706e6e !important;
  line-height: 25px;
}
.disbleBtnDynamic .rec-arrow-left:hover:enabled,
.disbleBtnDynamic .rec-arrow-left:focus:enabled,
.disbleBtnDynamic .rec-arrow-left:hover {
  border: 0 !important;
  box-shadow: none;
  font-size: 14px;
  width: 25px;
  height: 25px;
  min-width: unset;
  border-radius: 5px;
  z-index: 999;
  cursor: pointer;
  background: #ececec !important;
  color: #706e6e !important;
  line-height: 25px;
}
.disbleBtnDynamic .rec-arrow-left:disabled,
.disbleBtnDynamic .rec-arrow-right:disabled {
  cursor: not-allowed;
  display: none;
}
.yt_slider_back {
  position: absolute;
  bottom: 0;
  left: 0%;
  width: 22px;
  height: 22px;
  border: 1px solid;
  border-radius: 5px;
  z-index: 999;
  cursor: pointer;
  background: #ececec;
  border: none;
  color: #706e6e;
}
.yt_slider_forward {
  position: absolute;
  bottom: 0;
  right: 91%;
  width: 22px;
  height: 22px;
  border: 1px solid;
  border-radius: 5px;
  z-index: 999;
  cursor: pointer;
  background: #ececec;
  border: none;
  color: #706e6e;
}
.border_empty_state {
  border: 1px solid;
  border-radius: 19px;
  margin: 12px;
}

@media screen and (max-width: 820px) {
  .Individual_middleScreen1 {
    flex-wrap: wrap;
  }
  .Individual_first_block {
    width: 100%;
  }
  .Individual_second_block {
    width: 100%;
    margin-top: 15px;
  }
  /* .s_m_box {
    width: 100%;
  } */

  .F_M_box_P {
    height: 117px;
  }
  .F_M_box_heading {
    font-size: 15px;
  }
  .T_M_windmil_image {
    width: 38%;
    height: 127px;
    /* padding: 0px 131px; */
  }
  .s_m_windmil_image {
    width: 46%;
    height: 105px;
  }
  .yt_slider_back {
    left: 47%;
  }
  .yt_slider_forward {
    right: 47%;
  }
}

@media screen and (max-width: 420px) {
  .yt_slider_back {
    left: 43%;
  }
  .yt_slider_forward {
    right: 43%;
  }

  .F_M_box_Img {
    width: 120px;
    height: 120px;
  }
  .F_M_box_Main1 {
    margin: 42px 12px;
  }
  .F_M_box {
    height: 225px;
  }
}

/*==================== IndiviDualMIddleScreenEmptyCart ===============*/

.E_M_box {
  height: 809px;
}

.E_M_ScreenP {
  text-align: center;
  padding: 0px;
}

.E_M_boxP {
  padding: 0px;
}

.E_Cart_Arrow {
  font-size: 44px;
  color: var(--color-dark-grey);
}

.E_M_S_Head {
  display: flex;
}

.E_M_ScreenP {
  font-size: 31px;
  padding-top: 4px;
  padding-left: 10px;
}

.E_M_Image {
  height: 172px;
}

.carousel-climate-project .rec-pagination {
  display: none !important;
}
.screen-loader-center {
  display: flex;
  justify-content: center;
  padding: 150px;
}
.screen-loader {
  width: 150px;
}
.carbonFootPrintPageBlock {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.fa-angle-left {
  cursor: pointer;
}
.Pie_chart {
  padding: 0px 15px;
  height: 100%;
  width: 100%;
}
.Pie_chart text {
  font-size: 10px !important;
}
.Pie_chart #reactgooglegraph-2 > div > div {
  margin: auto !important;
}
.indi_donut_chart p {
  position: absolute;
  top: 12.5rem;
  z-index: 9999;
  right: 23.5rem;
  font-size: 20px;
  font-weight: 600;
}
/* --------------Mobile Responsive Start css by Shlok */
@media screen and (min-width: 370px) and (max-width: 767px) {
  .head-off-plant-text {
    font-size: 13px;
  }
  .first_block_img .blockImg {
    width: 27px;
    height: 27px;
    margin: 5px 8px 0px 25px;
  }
  .blockImg {
    width: 27px;
    height: 27px;
    margin: 5px 8px 0px 0px;
  }
  .firstsquareT {
    font-size: 14px;
  }
  .head-off-plant-text {
    display: unset;
  }
  .first_blocklastP_main {
    padding: 0px 12px;
    font-size: 12px;
  }
  .first_btn {
    font-size: 12px;
    padding: 10px 10px;
  }
  .first_block_rightP {
    font-size: 12px;
    padding: 10px 0px;
  }
 
  .Bussiness_Impact_Offset {
    width: 100%;
  }
  .Bussiness_project_distribution {
    width: 100%;
  }

  .F_M_box_heading {
    font-size: 12px;
  }
  .F_M_box_P {
    font-size: 12px;
  }
  .business_first_square .firstsquareT {
    padding-top: 8px;
  }
  .Individual_MiddleScreenMain {
    padding: 15px;
  }
  .carbon_heading {
    font-size: 20px;
  }
  .climate_project_home {
    flex-flow: row wrap !important;
  }
  .Individual_first_square {
    height: 185px;
  }
}
@media (min-width: 768px) and (max-width: 1366px) {
  .firstsquareT {
    padding-top: 0;
  }
  .T_M_Header_Center {
    height: 400px;
  }
  .indi_donut_chart p {
    right: 20rem;
  }
  .Individual_first_square {
    height: 185px;
  }
}
.p_c_box_Section {
  display: contents;
  font-weight: 600;
  color: #3f161a;
  cursor: pointer;
  padding-left: 4px;
}
/* --------------Mobile Responsive End */
.for_success {
  position: relative;
}
.usd_success {
  font-weight: 600 !important;
  color: #88ae47 !important;
}
.usd_success_tonnes {
  font-size: 12px;
}
.success_position {
  position: absolute;
  right: 0;
  top: 5px;
}

#components-layout-demo-responsive .logo {
  height: 32px !important;
  margin: 16px !important;
  background: rgba(255, 255, 255, 0.2) !important;
}

.indv-layout .ant-layout-header {
  padding: 0px 24px !important;
  box-shadow: 0px 12px 10px -10px rgb(0 0 0 / 7%);
  z-index: 1;
}

.indv-logo-head {
  height: 64px !important;
  background: #fff !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.site-layout-sub-header-background {
  background: #fff !important;
  line-height: unset !important;
  display: flex !important;
  justify-content: flex-end !important;
}

.site-layout-sub-header-background2 {
  background: #fff !important;
  line-height: unset !important;
  display: flex !important;
  justify-content: space-between !important;
}

.site-layout-background {
  background: #fff !important;
}

.indv-dash-sidebar {
  background: #f8f6f7 !important;
}

.indv-dash-sidebar .ant-menu.ant-menu-dark {
  background: #f8f6f7 !important;
}

.indv-dash-sidebar .ant-menu-title-content {
  color: #89898b !important;
}

.indv-dash-sidebar .anticon {
  color: #89898b !important;
}

.indv-dash-sidebar
  .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal)
  .ant-menu-item-selected {
  background-color: #eeeced !important;
}

.indv-dash-sidebar .ant-menu-dark .ant-menu-item > span > a {
  color: #89898b !important;
}

.indv-dash-side-img img {
  width: 24px;
  margin-right: 10px;
}
.image_side_nav{
  position: absolute;
  left: 0;
  width: 7px;
  top: 13px;
  display: none;
}
.indv-dash-sidebar .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  /* width: 85%;
  margin-left: 25px; */
  /* background-color: #eeeced !important; */
  /* border-radius: 6px;
  padding-left: 12px !important; */
}
.ant-menu-dark.ant-menu-inline .ant-menu-item, .ant-menu-dark.ant-menu-inline .ant-menu-submenu-title {
  /* width: 100%;
  padding-left: 35px !important; */
}
.indv-faq-head {
  display: flex;
  align-items: center;
}

.indv-faq-head p {
  margin-bottom: 0px;
  font-weight: 600;
  font-size: 18px;
}

.indv-faq-head button {
  border: none;
  border-radius: 36px;
  height: 34px;
  width: 34px;
  margin-right: 25px;
}

.indv-faq-sec .ant-collapse > .ant-collapse-item {
  background: #ffffff !important;
  border: none;
  margin-bottom: 10px;
  border-radius: 10px;
  box-shadow: 0px 0px 13px 5px rgba(0, 0, 0, 0.12);
  padding: 8px 0 !important;
}

.indv-faq-sec .ant-collapse {
  background-color: none !important;
  border: none !important;
}

.indv-faq-sec .ant-collapse > .ant-collapse-item:last-child,
.ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
  border-radius: 10px !important;
}

.indv-faq-sec .ant-collapse-content {
  border-top: unset !important;
}
.indv-faq-sec .collapse_arrow{
  width: 22px;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header{
  font-weight: bolder;
}
.ul_li_list li{
  list-style: unset ;
}
.indv-add-cart-sec .ant-collapse > .ant-collapse-item {
  background: #ffffff !important;
  border: none;
  margin-bottom: 10px;
  border-radius: 10px;
  box-shadow: 0px 0px 13px 5px rgba(0, 0, 0, 0.12);
  padding: 8px 0 !important;
}

.indv-add-cart-sec .ant-collapse {
  background-color: none !important;
  border: none !important;
}

.indv-add-cart-sec .ant-collapse > .ant-collapse-item:last-child,
.ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
  border-radius: 10px !important;
}

.indv-add-cart-sec .ant-collapse-content {
  border-top: unset !important;
}
.indiv_add_cart_table .product-label-tbl {
  display: flex;
}
.indiv_add_cart_table .product-label-tbl p {
  font-weight: 800;
  padding-left: 5px;
  padding-top: 10px;
}
.indiv_add_cart_table .product-label-imgLbl-tbl {
  display: flex;
  align-items: center;
}
.indiv_add_cart_table .product-label-imgLbl-tbl p {
  font-weight: 800;
  padding-top: 10px;
}
.indiv_add_cart_table .product-label-imgLbl-tbl img {
  width: 50px;
  height: 50px;
  border-radius: 6px;
}
.indiv_add_cart_table .ant-collapse-header {
  color: #2a2a2a;
  font-size: 18px;
  font-weight: 900;
}
.indiv_add_cart_table .ant-table-thead > tr > th {
  position: relative;
  color: #ababab;
  font-weight: 600;
  background: transparent;
  border-bottom: none;
}
.indiv_add_cart_table
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  background-color: transparent;
}
.indv-cart-btn {
  width: 250px;
  background-color: #88ae47 !important;
  color: #ffffff !important;
  border-radius: 8px !important;
  height: 48px !important;
  border: none !important;
  margin-top: 15px;
}
.cart-border-top {
  border-top: 1px solid #ababab;
}
.indiv_add_cart_table .ant-collapse-content > .ant-collapse-content-box {
  padding: 0;
}
.indiv_add_cart_table .cart-border-top {
  padding: 16px;
}
.tble-circle-img {
  cursor: pointer;
}
.cart-radio-button {
  display: flex;
  flex-direction: column;
}
.cart-radio-button .ant-radio-wrapper .ant-radio-inner {
  border-color: #629e9f;
  width: 20px;
  height: 20px;
  border-width: 3px;
}
.cart-radio-button .ant-radio-wrapper .ant-radio-inner::after {
  background-color: #629e9f;
}
.payment-method-input {
  display: flex;
  flex-direction: column;
}
.payment-method-input .ant-form-item {
  margin-bottom: 0;
}
.payment-method-input .ant-input {
  border-radius: 8px;
  height: 50px;
}
.payment-method-input .ant-input-lg {
  padding: 6.5px 15px;
}
.cart-border {
  border-top: 1px solid #ababab;
  margin: 15px 0;
}
.debit-credit-card {
  padding: 20px 25px;
}
.debit-credit-card .ant-card {
  border-radius: 8px !important;
}
.debit-credit-card .ant-card .ant-card-body {
  padding: 0;
}
.payment-method-input .ant-radio-wrapper {
  align-items: center;
}
.saved-card-details {
  display: grid;
  padding-left: 10px;
}
.saved-card-details-img img {
  width: 35px;
}
.saved-card-details p {
  margin-bottom: 0;
}

.custom-card-detail-enter .sc-bczRLJ {
  width: 100% !important;
}

.custom-card-detail-enter .sc-gsnTZi {
  height: 50px !important;
  border-radius: 8px !important;
  border: 1px solid #d9d9d9 !important;
}
.custom-card-detail-enter #cardNumber {
  width: 75% !important;
}
.padding-r {
  padding-right: 15rem;
}
.trashCard img{
  width: 20px;
  cursor: pointer;
}

.pay_btn{
  background: var(--color-individual) !important;
  color: #fff !important;
}
.indv-privacy-sec-head p {
  margin-bottom: 0;
  font-weight: 600;
  font-size: 16px;
}

.indv-privacy-sec-text p {
  margin-bottom: 0;
  text-align: justify;
}

.reset-pass-succ-modal{
    min-height: 350px !important;
}

.indv-profile-bg{
    background: url("https://minio.b120578.dev.eastus.az.svc.builder.cafe/sbucket/variants/k64fzey62bj1y9cz7qgckdnqhr86/6f7daa6c86a9bc2c53f5d0684447f253adfa8f13401d95f9d66464245ed9ff2e?response-content-disposition=inline%3B%20filename%3D%221eeaff1adb2ac227e5c20751c15edde4f86081b8.jpg%22%3B%20filename%2A%3DUTF-8%27%271eeaff1adb2ac227e5c20751c15edde4f86081b8.jpg&response-content-type=image%2Fjpeg");
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
}
.div_class{
    background-color: transparent;
}
.ant-btn-default.csncel_subcription{
    background-color: #fff !important;
    color: var(--bs-dark) !important;
}
.indv-climate-card {
  padding: 12px;
  box-shadow: 0px 0px 12px -2px rgba(0, 0, 0, 0.43);
  border-radius: 14px;
}

.indv-climate-card-earn {
  box-shadow: 0px 0px 12px -2px rgba(0, 0, 0, 0.43);
  border-radius: 14px;
  margin-top: 24px;
}

.indv-climate-card-img img {
  width: 100%;
  border-radius: 12px;
  height: 310px;
}

.indv-climate-card-img {
  position: relative;
}

.indv-climate-card-img-overlay {
  position: absolute;
  top: 72%;
  opacity: 0;
}

.indv-climate-card-img:hover .indv-climate-card-img-overlay {
  opacity: 1;
  width: 100%;
}

.indv-climate-card p {
  margin-bottom: 0 !important;
}

.indv-climate-card-head {
  color: #a7a7a7 !important;
  font-weight: 600;
  font-size: 14px;
}

.indv-climate-card-text {
  font-weight: 600;
  font-size: 15px;
}

.indv-climate-card-date-txt {
  font-size: 12px;
  color: #a7a7a7 !important;
}

.indv-climate-card-date {
  font-weight: 600;
  font-size: 15px;
}

.indv-climate-card-earn-head {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 8px;
}

.indv-climate-card-earn-text {
  color: #a7a7a7 !important;
  font-size: 14px;
  margin-bottom: 0;
}

.indv-climate-card-earn-img img {
  width: 80%;
}

.indv-climate-card-img-overlay-download-btn {
  background-color: #6f7a51;
  border-radius: 50%;
  padding: 12px;
  width: 48px;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.indv-climate-card-img-overlay-download-btn span i {
  color: #ffffff;
}

.indv-climate-card-img-overlay-share-btn {
  background-color: #9d9d9d;
  width: 100%;
  padding: 10px;
  border-radius: 18px;
}

.indv-climate-card-img-overlay-share-btn p,
.indv-climate-card-img-overlay-share-btn span {
  color: #ffffff;
  cursor: pointer;
}

.indv-payment-success-sec p {
  margin-bottom: 0;
  font-weight: 500;
}
.indv-payment-success-done-sec-up {
  padding-top: 50px;
}
.indv-payment-success-done-sec-up-img img {
  width: 25%;
}
.payment-success-card-select {
  border: 3px solid #88ae47;
  border-radius: 24px;
  height: 390px;
}

.indv-payment-success-sec-head {
  font-weight: 600;
  font-size: 15px;
}

.indv-payment-success-sec-text1,
.indv-payment-success-sec-text2 {
  font-size: 13px;
}

.indv-payment-success-done-sec-down-head p {
  margin-bottom: 0;
  font-weight: 600;
  font-size: 15px;
}

.indv-payment-foot {
  box-shadow: 0px -2px 10px 0px rgba(0, 0, 0, 0.26);
  background-color: #fff;
}

.carbon-offset-payment-success-foot-btn {
  background-color: #88ae47 !important;
  padding: 0px 56px !important;
  border-radius: 6px !important;
  color: #fff !important;
  height: 45px !important;
}
.indv-payment-success-alert {
  background-color: #fff;
  padding: 32px;
  border-radius: 12px;
}

.indv-payment-success-alert p {
  margin-bottom: 0;
}

.indv-payment-success-alert-head {
  font-size: 14px;
  font-weight: 600;
}

.indv-payment-success-alert-text {
  font-size: 13px;
}

.indv-payment-success-alert-img {
  display: flex;
  justify-content: center;
}

.indv-payment-success-alert-img img {
  width: 205px;
}

.payment-success-foot .ant-affix {
  z-index: 9999 !important;
}

.landing-header {
  padding: 40px 140px;
  background: #f4f4f4;
}
.landing-header h2 {
  text-transform: uppercase;
}
.page-content {
  padding: 40px 250px;
  font-size: 18px;
  text-align: justify;
}
.read-pagination {
  text-align: center;
}
.faq-page-content {
  padding: 40px 130px;
  font-size: 18px;
}
.faqs-collapse .ant-collapse-item .ant-collapse-header {
  color: #88ae47 !important;
}
.indivi_about_us_content {
  padding: 75px 0 0 0;
}
.indivi_about_us .text_bold {
  font-size: 32px;
  text-transform: uppercase;
  font-weight: 900;
  font-family: system-ui;
}
.indivi_about_us .text_bold .normal {
  font-weight: normal;
}
.indivi_about_us img {
  width: 100%;
}
.about_mission_vision_bgc {
  background-color: #fafafa;
  /* padding: 60px 130px; */
}
.indivi_about_us_data {
  height: 110px;
  overflow: auto;
}
.mission_Vision_content {
  height: 210px;
  overflow: auto;
}
.indivi_about_us_data::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.indivi_about_us_data::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5;
}

.indivi_about_us_data::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #e0e0e0;
}
.mission_Vision_content::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.mission_Vision_content::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5;
}

.mission_Vision_content::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #e0e0e0;
}
.about-us-our-miss-img img {
  height: 250px;
}

.about-us-our-vis-img img {
  height: 285px;
}

.indivi_about_us_mission_vision .mission_vision_normal {
  font-weight: normal;
  font-size: 32px;
  text-transform: uppercase;
}
.indivi_about_us_mission_vision .mission_vision_bold {
  font-weight: 900;
  font-family: system-ui;
}

.text_bold {
  font-weight: 900;
  font-family: system-ui;
}

.page-content-redeem {
  padding: 40px 250px;
  font-size: 18px;
}
.page-content-redeem img {
  width: 100%;
}
.redeemgift_content {
  padding-top: 50px;
}
.redeemgift_content label {
  font-weight: 900;
  padding-bottom: 25px;
}
.redeemgift_content h3 {
  font-size: 30px;
  color: #88ae47;
  font-weight: 600;
}
.redeemgift_content p {
  color: #9e9e9e;
  text-align: justify;
}
.rd-pd-left {
  padding-left: 50px;
}
.rd-pading-tb {
  padding: 25px 0;
}
.page-content-contact-us {
  padding: 45px 140px;
}
.contact-card-box {
  border: 2px solid #f0eeef;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
}
.contact-card-box img {
  width: 20px;
}
.contact-card-box label {
  padding-left: 10px;
  font-size: 16px;
  line-height: 0;
  padding-left: 15px;
  display: contents;
}
.contact-card-box label p {
  font-size: 15px;
  font-weight: 600;
}
.contact-card-box a {
  color: black;
}
.contact-card-box .mail-point {
  cursor: pointer;
}
.contact-card-box a:hover {
  color: black;
}
.contact-form {
  margin-top: 40px !important;
}
.contact-form .ant-row p {
  font-weight: 500;
  color: #6d6c71;
}
.contact-form .ant-input {
  height: 50px;
  border-radius: 10px;
}
.just-cenrte {
  justify-content: center;
}
.form_textarea {
  min-height: 160px;
  max-height: 160px;
}

.about-us-mission-sec {
  margin-left: 105px;
}

.about-us-vission-sec {
  margin-left: 75px;
}
.about-us-vission-sec p {
  width: 480px;
}
.about-us-mission-sec p {
  width: 480px;
}

.about-mis-vis-border {
  border-right: 2px solid #ededed;
}
@media screen and (min-width: 768px) and (max-width: 1000px) {
  .page-content-contact-us {
    padding: 45px 50px;
  }
  .contact-card-box {
    margin-bottom: 15px;
  }
  .page-content-contact-us .ant-row {
    flex-direction: column;
  }
  .page-content-contact-us .ant-col-xs-8 {
    display: contents;
  }
}
@media screen and (min-width: 425px) and (max-width: 769px) {
  .page-content-contact-us {
    padding: 45px 50px;
  }
  .contact-card-box {
    margin-bottom: 15px;
  }
  .page-content-contact-us .ant-row {
    flex-direction: column;
  }
  .page-content-contact-us .ant-col-xs-8 {
    display: contents;
  }
  .contact-card-box label p {
    font-size: 14px;
  }
}
@media screen and (min-width: 370px) and (max-width: 670px) {
  .page-content-contact-us {
    padding: 45px 50px;
  }
  .contact-card-box {
    margin-bottom: 15px;
  }
  .page-content-contact-us .ant-row {
    flex-direction: column;
  }
  .page-content-contact-us .ant-col-xs-8 {
    display: contents;
  }
  .contact-card-box label p {
    font-size: 14px;
  }
  .faq-page-content {
    padding: 25px 25px;
  }
  .landing-header {
    padding: 30px 60px;
    text-align: center;
  }
  .page-content-redeem {
    padding: 10px 25px;
    font-size: 16px;
  }
  .rd-pd-left {
    padding-left: 10px;
  }
  .redeemgift_content {
    padding-top: 10px;
  }
  .redeemgift_content h3 {
    font-size: 24px;
  }
}

.about-us-sec-climate-crisis {
  padding: 75px 0 85px 0;
}

.about-us-sec-climate-crisis .head h1 {
  font-size: 32px;
  font-weight: 400;
  text-align: center;
}

.about-us-sec-climate-crisis .head h1 span {
  font-weight: 700;
}

.about-us-sec-climate-crisis .text p {
  font-size: 18px;
  text-align: center;
  font-weight: 400;
  color: #000;
}

.about-hate-climate-chng-sec {
  padding: 75px 0;
  background-color: #f5f5f5;
}

.about-hate-climate-chng-sec .head p {
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 15px;
  margin-left: -5px;
}

.about-hate-climate-chng-sec .head-green {
  color: #5b851f !important;
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.about-hate-climate-chng-sec .text {
  font-weight: 400;
  color: #333;
  font-size: 14px;
  margin: 0;
}

.eko-positive-sec {
  padding: 80px 0;
}

.eko-positive-sec-img-bg {
  background: url(/static/media/Mountain_windmill_Bg.478995e5.svg);
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 260px;
  position: relative;
}

.eko-positive-sec p {
  position: absolute;
  font-weight: 400;
  font-size: 44px;
  color: #575757;
  text-align: center;
  top: 24%;
  left: 9%;
}

.eko-positive-sec p span {
  color: #5b851f !important;
  font-weight: 600;
}

.about-us-mission-vision-sec {
  padding: 50px 0;
  background-color: #f5f5f5;
}

.about-our-mission-sec {
  display: flex;
  justify-content: flex-start;
  width: 80%;
  margin: auto;
  grid-gap: 15px;
  gap: 15px;
}
.about-our-mission-sec .head p {
  color: #5b851f !important;
  font-weight: 600;
  font-size: 35px;
  line-height: 42px;
  margin-bottom: 0;
}
.about-our-mission-sec .border-sec {
  padding: 10px 25px;
}

.about-our-mission-sec .border-sec hr {
  border: solid 1px #000 !important;
  height: 100% !important;
  margin: 0 !important;
}

.about-our-mission-sec .text {
  display: flex;
  align-items: center;
}

.about-our-mission-sec .text p {
  font-weight: 500;
  font-size: 20px;
  color: #000;
  margin: 0;
}

.about-our-vision-sec {
  display: flex;
  justify-content: flex-end;
  width: 80%;
  margin: auto;
  grid-gap: 20px;
  gap: 20px;
}

.about-our-vision-sec .head p {
  color: #5b851f !important;
  font-weight: 600;
  font-size: 35px;
  line-height: 42px;
  margin-bottom: 0;
  text-align: left;
}

.about-our-vision-sec .border-sec {
  padding: 10px 25px;
}

.about-our-vision-sec .border-sec hr {
  border: solid 1px #000 !important;
  height: 100% !important;
  margin: 0 !important;
}

.about-our-vision-sec .text {
  display: flex;
  align-items: center;
}

.about-our-vision-sec .text p {
  font-weight: 500;
  font-size: 20px;
  color: #000;
  text-align: right;
  margin: 0;
}

.about-us-meet-team-sec {
  padding: 75px 0 0 0;
}

.about-us-meet-team-sec .head p {
  text-align: center;
  color: #5b851f !important;
  font-weight: 600;
  font-size: 28px;
  margin-bottom: 40px;
}

.about-us-meet-team-sec .img-sec {
  background-color: #ebebeb;
  height: 100%;
}

.about-us-meet-team-sec .img-sec img {
  padding: 25px;
  width: 100%;
  height: 460px;
}

.about-us-meet-team-sec .text-sec {
  background-color: #fafafa;
  height: 100%;
  padding: 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.about-us-meet-team-sec .text-sec .head {
  font-weight: 600;
  font-size: 22px;
  margin: 0;
}

.about-us-meet-team-sec .text-sec .text1 {
  font-size: 16px;
  color: #000;
  line-height: 32px;
  text-align: justify;
}

.about-us-meet-team-sec .text-sec .text2 {
  font-size: 16px;
  color: #5b851f;
  /* font-weight: 600; */
  font-style: italic;
  text-align: justify;
}

.about-us-meet-team-sec .text-sec .team_p-t {
  font-size: 16px;
  color: #5b851f;
  /* font-weight: 600; */
  text-align: justify;
  font-style: italic;
  padding-top: 40px;
}

.about-us-meet-team-sec-box {
  box-shadow: 0px 3px 8px 1px rgba(186, 186, 186, 1);
}

.about-us-sec-climate-crisis-img img {
  width: 100%;
}

.about-hate-climate-chng-sec-img img {
  width: 100%;
}

/* .about-hate-climate-chng-text-sec{
  display: flex;
} */

.about-bottom-img-sec {
  background: url("https://minio.b120578.dev.eastus.az.svc.builder.cafe/sbucket/variants/cing3o7a0t6w0tt0abcnentc25yo/6f7daa6c86a9bc2c53f5d0684447f253adfa8f13401d95f9d66464245ed9ff2e?response-content-disposition=inline%3B%20filename%3D%22PlasticBag%20Man_AboutUs.jpg%22%3B%20filename%2A%3DUTF-8%27%27PlasticBag%2520Man_AboutUs.jpg&response-content-type=image%2Fjpeg");
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 245px;
}

.about-bottom-img-sec2 {
  background: url("https://minio.b120578.dev.eastus.az.svc.builder.cafe/sbucket/variants/vn9pkryil5v095hb1s3xl1l3av59/6f7daa6c86a9bc2c53f5d0684447f253adfa8f13401d95f9d66464245ed9ff2e?response-content-disposition=inline%3B%20filename%3D%22OceanTurtle_AboutUs.jpg%22%3B%20filename%2A%3DUTF-8%27%27OceanTurtle_AboutUs.jpg&response-content-type=image%2Fjpeg");
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 245px;
}

.about-us-abstract-sec {
  margin: 75px 0;
}

.about-bottom-img-sec p,
.about-bottom-img-sec2 p {
  margin-bottom: 0;
  color: #ffffff;
}

.about-bottom-img-sec-overlay,
.about-bottom-img-sec2-overlay {
  background-color: #00000061;
  padding-left: 50px;
  min-height: 400px;
  padding-top: 10rem;
}

.about-bottom-img-sec-btn button {
  background-color: #ffffff;
  color: #87ac44;
  border: none;
  height: 36px;
  padding: 0px 25px;
  font-weight: 500;
}

.about-bottom-img-sec-overlay .head,
.about-bottom-img-sec2 .head {
  font-weight: 600;
  font-size: 30px;
}
.about-bottom-img-sec-overlay .text,
.about-bottom-img-sec2 .text {
  font-weight: 400;
  font-size: 14px;
  padding: 12px 0;
}
.about-bottom-img-sec-social {
  display: flex;
  align-items: center;
}
.about-bottom-img-sec-social-linked img {
  width: 27px;
  margin-left: 12px;
}
@media only screen and (max-width: 1400px) {
  .about-our-mission-sec .text p {
    font-weight: 500;
    font-size: 18px;
    color: #000;
    margin: 0;
  }
  .about-our-vision-sec .text p {
    font-weight: 500;
    font-size: 18px;
    color: #000;
    text-align: right;
    margin: 0;
  }
  .about-our-vision-sec .border-sec {
    padding: 10px 25px;
    /* margin-left: 55px; */
  }
  .about-our-mission-sec .border-sec {
    padding: 10px 25px;
    /* margin-right: 55px; */
  }
  .eko-positive-sec p {
    color: #575757;
  }
  .about-us-meet-team-sec .text-sec .text1 {
    font-size: 16px;
  }
  .about-us-meet-team-sec .text-sec .team_p-t {
    font-size: 16px;
    margin-bottom: 0;
  }
  .about-us-meet-team-sec .text-sec .text2 {
    font-size: 16px;
    margin-bottom: 0;
  }
}

@media (min-width: 768px) and (max-width: 1366px) {
  .about-hate-climate-chng-sec .text {
    font-size: 14px !important;
  }
}

@media screen and (max-width: 768px) {
  .about-us-sec-climate-crisis .head h1 {
    font-size: 22px;
    font-weight: 400;
    text-align: center;
  }
  .about-hate-climate-chng-sec .head p {
    text-align: center;
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 15px;
    margin-left: 0px;
  }
  .about-hate-climate-chng-sec .head-green {
    color: #5b851f !important;
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 0.5rem;
  }
  .eko-positive-sec-img-bg {
    min-height: 92px;
  }
  .eko-positive-sec p {
    position: absolute;
    font-weight: 400;
    font-size: 18px;
    color: #575757;
    text-align: center;
    top: 6%;
    left: 0%;
  }
  .border-sec {
    display: none;
  }
  .about-our-mission-sec {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    margin: auto;
    grid-gap: 15px;
    grid-gap: 15px;
    gap: 15px;
    flex-direction: column;
  }
  .about-our-mission-sec .head p {
    color: #5b851f !important;
    font-weight: 600;
    font-size: 28px;
    line-height: 32px;
    margin-bottom: 0;
  }
  .about-our-vision-sec {
    display: flex;
    width: 100%;
    margin: auto;
    grid-gap: 20px;
    grid-gap: 20px;
    gap: 20px;
    flex-direction: column-reverse;
  }
  .about-our-vision-sec .text p {
    font-weight: 500;
    font-size: 18px;
    color: #000;
    text-align: left;
    margin: 0;
  }
  .about-our-vision-sec .head p {
    color: #5b851f !important;
    font-weight: 600;
    font-size: 28px;
    line-height: 32px;
    margin-bottom: 0;
    text-align: left;
  }

  .about-full-width {
    min-width: 100%;
    margin-bottom: 20px;
  }
  .head-spacing {
    margin-top: 20px;
  }
  .about-hate-climate-chng-sec-img img {
    width: 100%;
    margin-bottom: 20px;
  }
  .about-bottom-img-sec-overlay,
  .about-bottom-img-sec2-overlay {
    padding-left: 30px;
    padding-top: 5rem;
    min-height: 335px;
  }
  .about-us-sec-climate-crisis {
    padding: 45px 0 45px 0;
  }
  .about-hate-climate-chng-sec {
    padding: 50px 0;
  }
  .eko-positive-sec {
    padding: 50px 0;
  }
  .about-us-meet-team-sec {
    padding: 50px 0 0 0;
  }
  .page-content{
    padding: 40px 35px;
  }
}

.individual-tour-container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}

.indv-tour-begin-sec {
  z-index: 99;
}

.tour-modal-close-btn {
  padding: 0 !important;
  background: none !important;
  width: unset !important;
}

.individual-tour-container .individual-tour-bg-image {
  width: 100%;
  /* height: 100vh; */
}

.text-center {
  text-align: center;
}

.individual-tour-sub {
  position: relative;
  width: 100%;
  height: 100vh;
  display: block;
}

.individual-tour-modal {
  max-width: 100%;
  padding: 20px 50px;
  border-radius: 15px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  background: #ffff;
  width: 425px;
  /* height: 225px; */
}

.individual-tour-arrow:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent #ffffff transparent;
  z-index: 9999;
}

.individual-tour1 {
  top: 163px;
  left: 74%;
}

.individual-tour1:before {
  left: -15px;
  bottom: 45%;
  transform: rotate(270deg);
}

.individual-tour2 {
  top: 170px;
  left: 60%;
}

.individual-tour2:before,
.individual-tour3:before,
.individual-tour4:before,
.individual-tour7:before {
  right: 50%;
  bottom: -10px;
  transform: rotate(180deg);
}

.individual-tour8:before {
  right: -3%;
  bottom: 95px;
  transform: rotate(90deg);
}

.individual-tour3 {
  top: 180px;
  left: 33%;
}

.individual-tour4 {
  left: 60%;
  top: 48%;
}

.individual-tour5 {
  top: 54%;
  left: 40%;
}

.individual-tour5:before {
  left: 50%;
  top: -8px;
  transform: rotate(0deg);
}

.individual-tour6:before,
.individual-tour9:before,
.individual-tourend:before {
  content: unset;
}

.individual-tour7 {
  top: 33%;
  left: 40%;
}

.individual-tour8 {
  top: 105%;
  right: 42%;
  left: unset;
}

.individual-tour10 {
  top: 24%;
  left: unset;
  right: -10%;
}

.individual-tour10:before {
  left: 59%;
  top: -8px;
  transform: rotate(0deg);
}

.individual-tour-modal-header {
  position: relative;
  font-size: 18px;
  font-family: "Roboto-Bold";
}

.individual-tour-modal-cross {
  position: absolute;
  top: 0;
  right: -10px;
  background: none;
  border: none;
  box-shadow: none;
}

.individual-tour-modal-body {
  font-weight: 600;
  color: #ababab;
  font-size: 15px;
  padding: 15px 0;
}

.individual-tour-green-btn,
.individual-tour-light-btn {
  padding: 10px;
  border: none;
  border-radius: 10px;
  color: #fff;
  font-family: "Roboto-Bold";
  font-size: 16px;
}

.tour-width-btn {
  width: 100% !important;
  padding: 10px;
}

.individual-tour-green-btn {
  background-color: #89aa51;
  width: 100px;
}

.individual-tour-light-btn {
  color: rgb(14, 14, 14);

  width: 100px;
}

.individual-tour-modal-footer {
  margin-top: 10px;
}

.infotext {
  color: #ababab;
  font-size: 16px;
}

.flex {
  display: flex;
}

.justify-content-between {
  justify-content: space-between;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .individual-tour-modal {
    width: 370px;
    padding: 10px 30px;
  }
  .individual-tour1 {
    top: 120px;
    left: 80%;
  }
  .individual-tour2 {
    top: 98px;
    left: 60%;
  }
  .individual-tour3 {
    top: 100px;
  }
  .individual-tour4 {
    top: 28%;
  }
  .individual-tour5 {
    top: 40%;
  }
  .individual-tour7 {
    top: 18%;
  }
  .individual-tour8 {
    top: 73%;
    right: 40%;
  }
  .individual-tour10:before {
    left: 80%;
  }
  .individual-tour10 {
    top: 19%;
    right: -9%;
  }
}
@media (min-width: 768px) and (max-width: 1366px) {
  .individual-tour1 {
    left: 76%;
  }
  .individual-tour2 {
    top: 130px;
  }
  .individual-tour3 {
    top: 150px;
    left: 35%;
  }
  .individual-tour4 {
    top: 47%;
  }
  .individual-tour5 {
    top: 56%;
    left: 39%;
  }
  .individual-tour7 {
    top: 32%;
  }
  .individual-tour8 {
    top: 112%;
  }
  .individual-tour10:before {
    left: 80%;
  }
  .individual-tour10 {
    top: 25%;
    right: -6%;
  }
}

.business-tour-container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}

.indv-tour-begin-sec {
  z-index: 99;
}

.tour-modal-close-btn {
  padding: 0 !important;
  background: none !important;
  width: unset !important;
}

.business-tour-container .business-tour-bg-image {
  width: 100%;
}

.text-center {
  text-align: center;
}

.business-tour-sub {
  position: relative;
  width: 100%;
  height: 100vh;
  display: block;
}

.business-tour-modal {
  max-width: 100%;
  padding: 20px 40px;
  border-radius: 15px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  background: #ffff;
  width: 380px;
}

.business-tour-arrow:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 10px 10px 10px;
  border-color: transparent transparent #ffffff transparent;
  z-index: 9999;
}

.business-tour1 {
  top: 250px;
  left: 15%;
}

.business-tour1:before {
  left: 60px;
  bottom: 100%;
  transform: rotate(360deg);
}

.business-tour2 {
  top: 170px;
  left: 74%;
}

.business-tour2:before {
  right: 99%;
  bottom: 95px;
  transform: rotate(270deg);
}

.business-tour8:before {
  right: -3%;
  bottom: 95px;
  transform: rotate(90deg);
}

.business-tour3 {
  top: 108%;
  left: 62%;
}
.business-tour3:before {
  right: 50%;
  bottom: 202px;
  transform: rotate(0deg);
}

.business-tour4 {
  left: 32%;
  top: 22%;
}
.business-tour4:before {
  right: 50%;
  bottom: -10px;
  transform: rotate(180deg);
}
.business-tour5 {
  top: 37%;
  left: 58%;
}

.business-tour5:before {
  left: 50%;
  top: 100%;
  transform: rotate(180deg);
}
.business-tour6 {
  top: 55%;
  left: 45%;
}

.business-tour6:before {
  left: 50%;
  top: -4%;
  transform: rotate(0deg);
}

.business-tour9:before,
.business-tourend:before {
  content: unset;
}

.business-tour7 {
  top: 70%;
  left: 53%;
}
.business-tour7:before {
  z-index: 0;
}

.business-tour8 {
  top: 105%;
  right: 45%;
  left: unset;
}

.business-tour10 {
  top: 24%;
  left: unset;
  right: -7%;
}

.business-tour10:before {
  left: 59%;
  top: -8px;
  transform: rotate(0deg);
}

.business-tour-modal-header {
  position: relative;
  font-size: 18px;
  font-family: "Roboto-Bold";
}

.business-tour-modal-cross {
  position: absolute;
  top: 0;
  right: -10px;
  background: none;
  border: none;
  box-shadow: none;
}

.business-tour-modal-body {
  font-weight: 600;
  color: #ababab;
  font-size: 15px;
  padding: 15px 0;
}

.business-tour-green-btn,
.business-tour-light-btn {
  padding: 10px;
  border: none;
  border-radius: 10px;
  color: #fff;
  font-family: "Roboto-Bold";
  font-size: 16px;
}

.tour-width-btn {
  width: 100% !important;
  padding: 10px;
}

.business-tour-green-btn {
  background-color: #89aa51;
  width: 100px;
}

.business-tour-light-btn {
  color: rgb(14, 14, 14);

  width: 100px;
}

.business-tour-modal-footer {
  margin-top: 10px;
}

.infotext {
  color: #ababab;
  font-size: 16px;
}

.flex {
  display: flex;
}

.justify-content-between {
  justify-content: space-between;
}

@media (min-width: 768px) and (max-width: 1366px) {
  .business-tour1 {
    left: 17%;
  }
  .business-tour2 {
    top: 162px;
  }
  .business-tour3 {
    top: 112%;
    left: 63%;
  }
  .business-tour4 {
    top: 20%;
    left: 33%;
  }
  .business-tour5 {
    top: 37%;
    left: 39%;
  }
  .business-tour6 {
    top: 57%;
    left: 45%;
  }
  .business-tour7 {
    top: 72%;
  }
  .business-tour8 {
    top: 105%;
    left: 29%;
  }
  .business-tour10 {
    top: 25%;
    right: -4%;
  }
  .business-tour10:before {
    left: 80%;
  }
}

.buss-offset-card {
  max-width: unset !important;
  min-width: unset !important;
}

.business-offset-flight-top-right {
  margin-bottom: 0;
}
.business-offset-flight-top-right li {
  display: block;
}
.business-offset-flight-top-right li a {
  color: #aaaaaa;
}

.business-offset-flight-top-right li:last-child {
  margin-left: 36px;
}

.flight-pass-data .flight-img-card img {
  width: 20px;
}

.flight-img-card {
  background-color: #ffffff;
}

.flight-pass-data .flight-img-card {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 6px;
  padding: 5px;
  cursor: pointer;
}

.bussiness-offset-flight-bydis-removebtn {
  margin-top: 56px;
}
.bussiness-offset-flight-35-removebtn {
  margin-top: 35px;
}

.busoness-offset-flight-bydis-label-marginbottm {
  margin-bottom: 21px !important;
}
.margin-top30 {
  margin-top: 30px !important;
}
.margin-top15 {
  margin-top: 15px !important;
}
.margin-btm15 {
  margin-bottom: 15px !important;
}
.offset-input-custm .ant-input-lg {
  height: 50px !important;
}
.webGreenColor {
  color: #88ae47;
  padding-bottom: 15px;
  padding-left: 30px;
  cursor: pointer;
}
.m-top-1 {
  margin-top: 1rem;
}

.business-offset-flight-tab a.active {
  color: #000000 !important;
  border-bottom: solid 2px #88ae47 !important;
  padding: 0px 24px 19px 24px;
}

.buss-summary-form-sec {
  border-top: 2px solid #e8e8e8;
}

.buss-sum-head-txt p {
  color: #aaaaaa !important;
  font-weight: 400;
  font-size: 15px;
  margin-bottom: 0 !important;
  text-transform: uppercase;
}

.buss-sum-head-txt2 p {
  color: #aaaaaa !important;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 0 !important;
  /* word-wrap: break-word; */
}

.buss-sum-head-txt-p p {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 0 !important;
  color: #000000;
}

.bus-offset-flight-sum-img-line {
  border: solid #dee2e6 1px;
  position: relative;
  width: 100%;
}

.bus-offset-flight-sum-img img {
  width: 36px;
  background-color: #ffffff;
  padding: 8px;
  border-radius: 36px;
}
.disabed-click {
  opacity: 0.5;
  cursor: not-allowed !important;
}

.buss-plant-sum-btm-txt-head p {
  color: #aaaaaa;
  font-weight: 500;
}

.buss-plant-sum-btm-txt-para ul li {
  color: #aaaaaa;
  font-weight: 500;
  list-style: none;
}
.add_new_card {
  color: #88ae47 !important;
}
.disp_content {
  display: contents;
}
.padding-top-Left {
  padding: 1rem 1.5rem;
}
.text_cap_lock {
  text-transform: uppercase;
}
.text_capital {
  text-transform: capitalize;
}
.carbon-offset-bg-img {
  margin-top: -40px;
}
.offset_tab_m-t {
  margin-top: 20px;
}
.text_error {
  color: red !important;
  font-size: 14px !important;
  font-weight: 300 !important;
  margin-bottom: -10px !important;
}
@media screen and (min-width: 370px) and (max-width: 670px) {
  .business-offset-flight-top-right{
    font-size: 12px;
  }
}
.offset_details h1 {
  text-align: center;
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
}
.offset_details_head p {
  margin: 0;
  font-size: 23px;
}
.offset_down_heade_back {
  padding: 22px 12px;
  background-image: linear-gradient(
      rgb(201 201 201 / 50%),
      rgba(201 201 201 / 50%)
    ),
    url("https://minio.b120578.dev.eastus.az.svc.builder.cafe/sbucket/variants/fk4eqwc85uqy5e13gfna6q7tu9tx/6f7daa6c86a9bc2c53f5d0684447f253adfa8f13401d95f9d66464245ed9ff2e?response-content-disposition=inline%3B%20filename%3D%22greenfield.jpg%22%3B%20filename%2A%3DUTF-8%27%27greenfield.jpg&response-content-type=image%2Fjpeg");
  background-size: cover !important;
}
.offset_down_heade_back_padding_top{
  padding-top: 70px;
}
.offset_down_heade {
  display: flex;
  justify-content: center;
}
.offset_down_heade h1 {
  color: #000000;
  text-align: initial;
  font-size: 27px;
  line-height: 35px;
  font-weight: 600;
}
.offset_down_heade p {
  color: #000000;
  text-align: initial;
}
.Tool_user {
  background-color: #fff;
}
.Tool_user_section {
  display: flex;
}
.Tool_user_number {
  background: -webkit-linear-gradient(#88ae47, #eee);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 75px !important;
  font-weight: bolder !important;
  padding: 0;
}
.down_Li {
  margin: 0px 0px 18px 36px;
  list-style-image: url(/static/media/emission_ul.1f0b9e4b.svg) !important;
}

.down_Li::marker {
  margin-top: 8px;
}

.offset-indv-car-img-sec {
  max-height: 455px;
  min-height: 455px;
}

.offset-indv-flight-img-sec {
  max-height: 385px;
  min-height: 385px;
}

.emissionsonflight-text2 {
  padding: 12px 36px;
}

.emissionsonflight-text2 p {
  font-weight: 410;
  font-size: 15px;
  text-align: justify;
}

.emissionsonflight-text2 p a {
  cursor: pointer;
  text-decoration: underline !important;
  font-weight: 800;
}
.a_tag {
  -webkit-text-decoration: inline !important;
          text-decoration: inline !important;
  margin: 0 6px 0 0;
  font-weight: 400;
}
.flight_upper_headding {
  position: absolute;
  left: -10px;
  top: 28%;
}
.flight_down_headding {
  position: absolute;
  height: 103px;
  right: 0px;
  bottom: 0%;
}
.seprate_text {
  text-align: justify;
  font-weight: 400;
  font-size: 15px;
}

.seprate_text span {
  position: relative;
  top: -5px;
  left: 8px;
}

.offset-emmision-new-p p {
  font-size: 23px !important;
  font-weight: 400;
}

.offset-emmision-new-h1 h1 {
  font-size: 26px !important;
  margin-top: 12px;
}

.offset-emmision-new-h1 h1 span {
  font-size: 26px !important;
  font-weight: 600 !important;
}
.subscription_btn {
  display: flex;
  justify-content: right;
}
@media screen and (min-width: 370px) and (max-width: 767px) {
  .offset_down_heade h1{
    font-size: 22px;
  }
}
.Indivdual_Project_MiddleContainer {
  display: flex;
  flex-direction: column;
  padding: 29px;
}

.Individual_Project_MidScreen_Main {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
}

.Individual_Project_first_block {
  height: 212px;
  background-color: var(--color-white);
  /* margin: 9px 10px; */
  border-radius: 22px;
}

.first_Project_block1 {
  display: flex;
  /* flex-direction: column; */
  width: 100%;
  height: 212px;
  /* background-color: var(--color-individual); */
  box-shadow: 0px 0px 16px -3px rgb(0 0 0 / 51%);
  border-radius: 28px;
}
.first_Project_block1_Main {
  margin: 37px 17px;
}

.first_Project_block_head {
  font-size: 22px;
  font-family: "Roboto-Bold";
}

.first_Project_block_P {
  letter-spacing: 1px;
  color: #969696;
  word-spacing: 2px;
}

.individual_first_project_square {
  background-color: var(--color-individual-secondsquare);
  box-shadow: 0px 0px 16px -3px rgb(0 0 0 / 51%);
}

.indv_project_squareT {
  color: var(--color-individual-secondsquareT);
  font-size: 16px !important;
}

.individual_first_projectP {
  display: flex;
}

.first_block_projectT {
  color: var(--color-individual-project-black);
}

.T_M_Project_box {
  padding: 0px 0px;
  margin: 0px;
  border: none;
}

.T_M_Project_box_inner {
  width: 36%;
  box-shadow: 0px 0px 16px -3px rgb(0 0 0 / 51%);
}

.Carousel_Project_Main {
  position: relative;
  height: 480px;
  /* margin-top: 52px; */
}

.order-history-myproject-height {
  height: auto !important;
}
.back-myproject {
  top: 32px !important;
  left: 91% !important;
}

.individual_project_r_energy {
  margin-top: 90px;
}

.Renawable_slider_back {
  top: 24% !important;
}

.Renawable_slider_forward {
  top: 24% !important;
}
.Renewable_energyP {
  font-size: 13px;
  margin: 29px 0px;
  font-family: "Roboto-Bold";
  color: var(--color-individual-i-b-amount);
}

.renewable_energy_inner1 {
  height: 43%;
}

.renewable_energy_image {
  height: 81%;
}

.renewable_energy_btn {
  background-color: var(--color-individual);
  border: none;
  color: var(--color-white);
}

.forestry_slider_back {
  top: 39% !important;
}

.forestry_slider_forward {
  top: 39% !important;
}

.individual_project_forestry {
  margin-top: 150px;
}

.energy_efficiency_slider_back {
  top: 52% !important;
}
.energy_efficiency_slider_forward {
  top: 52% !important;
}

.individual_project_Energy_Efficiency {
  margin-top: 210px;
}

.IndividualInvestIn_Climate {
  margin-top: 30px;
  width: 100%;
}

.IndividualInvestIn_Climate_block1 {
  height: 220px;
}

.IndividualInvestIn_Climate_block1_Main {
  margin: 37px 17px;
  width: 50%;
}

.IndividualInvestIn_ClimateP {
  width: 100%;
  font-size: 15px;
}

.InvestClimateS a {
  color: var(--color-individual);
  font-family: "Roboto-Bold";
  padding: 0 4px;
}
.InvestClimateS a:hover {
  color: var(--color-individual);
}
.invest_climate_project_img {
  height: 100%;
  border-radius: 52px;
}
.invest_climate_project_img_main {
  width: 100%;
  height: 100%;
}
.climate_images {
  width: 100%;
  height: 100%;
}
.carousel-climate-project .rec-arrow:disabled {
  cursor: not-allowed;
  display: none;
}
.carsousal-items {
  justify-content: center;
  align-items: center;
  display: flex;
}
.carousel-climate-project_width {
  width: 92% !important;
}
.carousel-climate-project .rec-arrow {
  background: none !important;
  border: 0;
  box-shadow: none !important;
  font-size: 17px;
  color: #333 !important;
}
.image_height_div {
  height: 185px;
}
.climate_project {
  display: flex;
  align-items: center;
}
.climate_project .Climate17Goals {
  width: 60rem;
  margin: 0;
  margin-right: auto;
  margin-left: auto;
}
.yt_slider_back_climate {
  position: unset !important;
  width: 65px;
  height: 45px;
  background: none;
}
.yt_slider_forward_climate {
  position: unset !important;
  width: 65px;
  height: 45px;
  background: none;
}
.project_crsl_slider_back_climate {
  position: unset !important;
  width: 50px;
  height: 35px;
  background: none;
}
.project_crsl_slider_forward_climate {
  position: unset !important;
  width: 50px;
  height: 35px;
  background: none;
}
.climate_upper_test {
  font-size: 18px;
  line-height: 30px;
  margin: 30px 0px;
}
.climate_upper_test span {
  color: #88ae47 !important;
}
.caurausal_main {
  width: 100%;
  background: #fafafa;
  padding: 28px 0px;
}
.standard_p {
  letter-spacing: 1.2px;
  padding: 25px 0px 20px 0px;
  font-size: 18px;
}
.standard_h4 {
  margin-bottom: 32px;
  font-size: 29px;
}
.climate_images_icon {
  width: 95%;
  padding: 12px;
}
.climate_images_2 {
  height: 100%;
  width: 100%;
}
.guIbWC {
  height: 100%;
}
@media screen and (min-width: 370px) and (max-width: 670px) {
  .climate_project .Climate17Goals {
    width: 24rem;
  }
  .first_Project_block_head {
    font-size: 16px;
  }
  .IndividualInvestIn_Climate_block1_Main {
    margin: 20px 15px;
    width: 100%;
  }
  .first_Project_block_P{
    font-size: 12px;
  }
  .first_Project_block1_Main{
    margin: 15px 10px;
  }
}

.Bussiness_project_distribution {
  /* width: 50%; */
  height: 94%;
  box-shadow: 0px 0px 16px -3px rgb(0 0 0 / 51%);
  border-radius: 12px;
  margin-top: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.one_more {
  display: block;
}

.Bussiness_Impact_Offset {
  /* width: 49%; */
  height: 94%;
  box-shadow: 0px 0px 16px -3px rgb(0 0 0 / 51%);
  /* margin-left: 10px; */
  border-radius: 12px;
  margin-top: 22px;
}

.Bussiness_project_distribution_h {
  font-family: "Roboto-Bold";
  font-weight: bold;
  padding-left: 24px;
  padding-top: 18px;
  font-size: 20px;
}

.Bussiness_project_distribution_inner_part1 {
  display: flex;
  height: 100px;
  align-items: center;
  justify-content: space-between;
  width: 94%;
}

.no-color{
  color: unset !important;
}

.Bussiness_project_distribution_inner_imagePart {
  display: flex;
  align-items: center;
  padding-left: 24px;
}

.Bussiness_project_distribution_inner_part_imagePart_Main {
  width: 43%;
  height: 40px;
}

.Bussiness_project_distribution_inner_part_imagePart_Main_SubImage {
  width: 37%;
}

.Bussiness_project_distribution_inner_part_image_sideArrow {
  width: 82%;
  height: 21px;
}
.Bussiness_project_distribution_inner_part {
  height: 101px;
  box-shadow: 0px 0px 16px -3px rgb(0 0 0 / 51%);
  /* margin-top: 14px; */
  border-radius: 12px;
}
.Bussiness_project_distribution_inner_imagePart_P {
  width: 160px;
  padding-left: 10px;
  font-family: "Roboto-Bold";
  font-size: 16px;
}

.bussiness_graph_height {
  height: 275px !important;
  padding: 22px;
}
.login-text-span {
  color: var(--color-individual);
  font-family: Roboto-Bold;
  padding-right: 4px;
}
.Business_Header_Center_empty{
  height: 300px;
}
:root {
  --color-primary: #3f7a4f;
  --color-black: #1c213a;
  --color-dark-grey: #7c8188;
  --color-white: #ffffff;
  --color-blue: #527bbe;
  --color-orange: #f67054;
  --color-sky-blue: #007bff;
  --color-green: #038b57;
  --color-red: #ff471a;
  --color-gray: #7c8188;
  --color-gold-yellow: #ffa500;
  --color-light-gray: #f2f6f8;
  --color-light-gray-hover: #7c81884f;
  --color-individual: #88ae47;
  --color-individual-firstsquareT: #7f9065;
  --color-individual-secondsquareT: #96aeb4;
  --color-individual-firstsquare: #e8f0db;
  --color-individual-secondsquare: #e1f4fa;
  --color-individual-white: #f4f8ea;
  --color-individual-gray: #cbcbcb;
  --color-individual-gray1: #c3c3c3;
  --color-green-individual-main: #88ae47;
  --color-green-individual-backgroud: #f8f6f7;
  --color-tabgreen-individual-backgroud: #bae481;
  --color-individual-Inner-box: #e6f1cf;
  --color-individual-i-b-amount: #a4b0b2;
  --color-individual-i-b-amount1: #94bcbf;
}

* {
  font-family: 'Poppins', sans-serif;
}

body {
  padding-right: 0 !important;
  /* background-color: var(--color-individual-gray) !important; */
  -webkit-user-select: none;
          user-select: none;
}
iframe {
  pointer-events: none !important;
}

li {
  list-style: none;
}

.modal-content {
  border-radius: 10px;
}

@media (min-width: 768px) {
  .cm-small-modal-6 {
    max-width: 600px;
  }
}

@media (max-width: 767.92px) {
  .cm-small-modal-6 {
    max-width: 335px;
    margin-left: auto;
    margin-right: auto;
  }
}

.ant-input {
  border-radius: 10px !important;
}
/* .ant-select-selector {
  border-color: rgba(204, 204, 204, 0.5) !important;
  box-shadow: none !important;
} */

