.idv-modal-upper-sec-back button {
  background: #ededed !important;
  border: none;
  border-radius: 15px;
  padding: 4px 12px;
}

.carbon-idv-forgot-btn {
  width: 100%;
}

.indv-pass-success-modal {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
}

.indv-pass-success-modal-check .fas {
  background: #f2f2f2 !important;
  padding: 28px !important;
  border: solid 3px #88ae47 !important;
  border-radius: 56px !important;
  font-size: 40px;
  color: #88ae47;
}

.indv-pass-success-modal-btn button {
  background-color: #88ae47 !important;
  border: none !important;
  padding: 0px 56px !important;
  border-radius: 6px !important;
  color: #fff !important;
  height: 48px !important;
}

.forgot-pass-idv-otp-enter-sec {
  text-align: center;
  padding: 60px 0;
}

.forgot-pass-idv-otp-enter-sec input {
  width: 60px !important;
  height: 55px !important;
  margin-right: 8px !important;
}

.ind-web-resend span {
  color: #88ae47 !important;
  font-weight: 500;
  cursor: pointer;
}
.idv-otp-form {
  bottom: -23px;
  position: absolute;
  width: 410px;
}
.create-new-pass-padding {
  padding: 70px 0;
}
@media screen and (min-width: 425px) and (max-width: 769px) {
  .forgot-pass-idv-otp-enter-sec input {
    width: 50px !important;
    height: 45px !important;
    margin-right: 8px !important;
  }
  .idv-otp-form {
    width: 360px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1000px) {
  .forgot-pass-idv-otp-enter-sec {
    padding: 50px 0;
  }
  .idv-otp-form {
    width: 340px;
  }
}
