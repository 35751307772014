.indv-privacy-sec-head p {
  margin-bottom: 0;
  font-weight: 600;
  font-size: 16px;
}

.indv-privacy-sec-text p {
  margin-bottom: 0;
  text-align: justify;
}
